import React from 'react';
import { shape } from 'prop-types';
import { parseRecordsTitle } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { CountLabel } from './SearchResultsHeader.styles';
var SearchResultsHeader = function SearchResultsHeader(_ref) {
  var data = _ref.data,
    staticProps = _ref["static"];
  var countLabel = staticProps.countLabel;
  var _ref2 = (data == null ? void 0 : data.search) || {},
    pagination = _ref2.pagination;
  var recordsTitle = {
    label: countLabel,
    records: pagination == null ? void 0 : pagination.records
  };
  return React.createElement(CountLabel, {
    "data-testid": "results-qty-label"
  }, (pagination == null ? void 0 : pagination.records) && parseRecordsTitle(recordsTitle));
};
process.env.NODE_ENV !== "production" ? SearchResultsHeader.propTypes = {
  data: shape({}),
  "static": shape({})
} : void 0;
SearchResultsHeader.defaultProps = {
  data: {},
  "static": {}
};
SearchResultsHeader.ssr = true;
export default withLayoutProps(SearchResultsHeader);