import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["title", "iconDataTestId", "message", "iconName"];
import React from 'react';
import { func, string } from 'prop-types';
import { Box, Flex, Text } from '@magalu/stereo-ui';
import { Icon } from '@magalu/stereo-ui-icons';
import withLayoutProps from '../../../hocs/withLayoutProps';
function SellerStamp(_ref) {
  var title = _ref.title,
    iconDataTestId = _ref.iconDataTestId,
    message = _ref.message,
    iconName = _ref.iconName,
    props = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(Box, _extends({
    width: 1,
    height: 70,
    mt: 3
  }, props), React.createElement(Flex, {
    columnGap: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "data-testid": "text-container"
  }, React.createElement(Icon, {
    name: iconName,
    "data-testid": iconDataTestId,
    width: 32,
    height: 32
  }), React.createElement(Text, {
    pb: 1,
    pt: 2,
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 1
  }, title), React.createElement(Text, {
    fontSize: "10px",
    lineHeight: 1
  }, message)));
}
SellerStamp.defaultProps = {
  iconDataTestId: '',
  message: '',
  title: ''
};
process.env.NODE_ENV !== "production" ? SellerStamp.propTypes = {
  iconDataTestId: string,
  iconName: func.isRequired,
  message: string,
  title: string
} : void 0;
SellerStamp.ssr = true;
export default withLayoutProps(SellerStamp);