import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import withLayoutProps from '../../../hocs/withLayoutProps';
import useShortenLink from '../../../hooks/useShortenLink';
import { WidgetContainer, WidgetOverlay, WidgetTriggerButton } from './ShortenWidget.styles';
import WidgetContent from './WidgetContent';
import useScrollLock from './useScrollLock';
var ShortenWidget = function ShortenWidget(_ref) {
  var StaticProps = _ref["static"];
  var device = StaticProps.device;
  var fullSearchUrl = window.location.href;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isWidgetActive = _useState2[0],
    setIsWidgetActive = _useState2[1];
  var _useState3 = useState(fullSearchUrl),
    _useState4 = _slicedToArray(_useState3, 2),
    urlToCopy = _useState4[0],
    setUrlToCopy = _useState4[1];
  var _useState5 = useState('full'),
    _useState6 = _slicedToArray(_useState5, 2),
    typeUrl = _useState6[0],
    setTypeUrl = _useState6[1];
  var shortenMagalyUrl = useShortenLink('shortenlink-magaly', {
    link: fullSearchUrl
  });
  var shortMagaly = shortenMagalyUrl.shortLink,
    handleShortenMagaly = shortenMagalyUrl.handleShortenLink,
    hasError = shortenMagalyUrl.hasError,
    isLoading = shortenMagalyUrl.isLoading;
  var handleSetUrlType = function handleSetUrlType(e) {
    setTypeUrl(e.target.value);
  };
  var resetShortenSearch = function resetShortenSearch() {
    setUrlToCopy(fullSearchUrl);
    setTypeUrl('full');
    window.sessionStorage.setItem('shorten_search_magaly', null);
  };
  useScrollLock(device, isWidgetActive);
  useEffect(function () {
    return window.location.href && resetShortenSearch();
  }, [window.location.href]);
  useEffect(function () {
    var storedMagalyUrl = window.sessionStorage.getItem('shorten_search_magaly');
    var hasNotStoredMagalyUrl = !storedMagalyUrl || storedMagalyUrl === 'null' || storedMagalyUrl === 'undefined';
    if (hasNotStoredMagalyUrl && typeUrl === 'shorten') handleShortenMagaly();
    if (typeUrl === 'shorten') {
      setUrlToCopy(shortMagaly);
    } else {
      setUrlToCopy(fullSearchUrl);
    }
  }, [typeUrl]);
  useEffect(function () {
    if (shortMagaly) {
      sessionStorage.setItem('shorten_search_magaly', shortMagaly);
      setUrlToCopy(shortMagaly);
    }
  }, [shortMagaly]);
  return React.createElement(WidgetContainer, {
    "data-testid": "shorten-widget",
    active: isWidgetActive,
    device: device
  }, React.createElement(WidgetTriggerButton, {
    "aria-label": "Encurtar link",
    device: device,
    "data-testid": "shorten-widget-button",
    onClick: function onClick() {
      return setIsWidgetActive(function (value) {
        return !value;
      });
    }
  }, React.createElement(Icon, {
    name: "Link"
  })), React.createElement(WidgetContent, {
    active: isWidgetActive,
    device: device,
    hasError: hasError,
    loading: isLoading,
    urlToCopy: urlToCopy,
    urlType: typeUrl,
    onChangeRadio: handleSetUrlType
  }), React.createElement(WidgetOverlay, {
    device: device,
    active: isWidgetActive,
    "data-testid": "shorten-widget-overlay",
    onClick: function onClick() {
      return setIsWidgetActive(false);
    }
  }));
};
process.env.NODE_ENV !== "production" ? ShortenWidget.propTypes = {
  "static": {
    device: string
  }
} : void 0;
ShortenWidget.defaultProps = {
  "static": {
    device: 'mobile'
  }
};
ShortenWidget.ssr = false;
ShortenWidget.displayName = 'ShortenWidget';
export default withLayoutProps(ShortenWidget);