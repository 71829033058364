import React from 'react';
import { string, shape } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import { Flex, Text, Button } from '@magalu/stereo-ui/atoms';
import { RegisterLink, IconWrapper, LoginButton } from './SidebarMenuPMD.styles';
var SidebarLoginHeading = function SidebarLoginHeading(_ref) {
  var storeName = _ref.storeName,
    theme = _ref.theme,
    cartUrl = _ref.cartUrl,
    domain = _ref.domain;
  return React.createElement(Flex, {
    bg: theme.palette.primary.base,
    alignItems: "center",
    "data-testid": "sidebar-heading-pmd",
    flexDirection: "column",
    p: "16px",
    width: "100%"
  }, React.createElement(Flex, {
    alignItems: "center",
    mb: "16px",
    width: "100%",
    justifyContent: "center",
    p: "8px 0"
  }, React.createElement(IconWrapper, {
    radii: "50%"
  }, React.createElement(Icon, {
    name: "Person",
    fill: "text.white"
  })), React.createElement(Text, {
    alignText: "center",
    color: "text.white",
    ml: "8px",
    fontSize: 3
  }, "Bem vindo! :)")), React.createElement(LoginButton, {
    forwardedAs: "a",
    full: true,
    mb: "10px",
    href: "".concat(cartUrl, "#/identificacao?next=").concat(domain, "/").concat(storeName, "&origin=magazinevoce"),
    customColor: {
      "default": '#ffffff',
      hover: theme.palette.primary.base
    },
    invertedColors: true
  }, "Entrar como cliente"), React.createElement(Button, {
    as: "a",
    variation: "outline",
    full: true,
    customColor: {
      "default": '#ffffff',
      hover: '#ffffff'
    },
    href: "".concat(domain, "/login/"),
    mb: "16px"
  }, "Entrar como influenciador"), React.createElement(RegisterLink, {
    bg: "primary",
    "data-testid": "register-link",
    href: "".concat(cartUrl, "#/identificacao?next=").concat(domain, "/").concat(storeName, "&origin=magazinevoce"),
    textAlign: "center",
    underline: false,
    underlineOnHover: true
  }, "ou cadastre-se"));
};
SidebarLoginHeading.defaultProps = {
  cartUrl: '',
  domain: '',
  storeName: '',
  theme: {}
};
process.env.NODE_ENV !== "production" ? SidebarLoginHeading.propTypes = {
  cartUrl: string,
  domain: string,
  storeName: string,
  theme: shape({})
} : void 0;
export default SidebarLoginHeading;