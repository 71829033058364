import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { shape, bool, number, string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import List from '@magalu/stereo-ui/molecules/List';
import Link from '@magalu/stereo-ui/atoms/Link';
import Sidebar from '@magalu/stereo-ui/organisms/Sidebar';
import { usePublisher } from '@magalu/mixer-publisher';
import { slug, buildSubcategoryPath, routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Container } from './SubCategoryList.styles';
function SubCategoryList() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    data = _ref.data,
    staticProps = _ref["static"];
  var _ref2 = staticProps || {},
    _ref2$basePath = _ref2.basePath,
    basePath = _ref2$basePath === void 0 ? '' : _ref2$basePath,
    _ref2$spa = _ref2.spa,
    spa = _ref2$spa === void 0 ? false : _ref2$spa,
    _ref2$show = _ref2.show,
    show = _ref2$show === void 0 ? 3 : _ref2$show,
    label = _ref2.label;
  var _ref3 = (data == null ? void 0 : data.category) || [],
    _ref4 = _slicedToArray(_ref3, 1),
    _ref4$ = _ref4[0],
    category = _ref4$ === void 0 ? {} : _ref4$;
  var _category$subcategori = category.subcategories,
    subcategories = _category$subcategori === void 0 ? [] : _category$subcategori;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    visible = _useState2[0],
    setVisible = _useState2[1];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var handleClick = function handleClick(_ref5) {
    var event = _ref5.event,
      type = _ref5.type,
      subcategory = _ref5.subcategory;
    publish("".concat(type, ":click"), {
      eventLabel: slug(subcategory == null ? void 0 : subcategory.label)
    });
    if (spa) {
      event.preventDefault();
      var path = subcategory.path;
      routePush({
        path: "".concat(basePath).concat(path),
        spa: spa
      });
    }
  };
  var handleClickSideBar = function handleClickSideBar() {
    setVisible(!visible);
    publish('subcategoryfulllist:click');
  };
  var subCategoryList = subcategories.map(function (sub) {
    return buildSubcategoryPath(sub, category);
  });
  var subCateList = subCategoryList.slice(0, show);
  return !!subcategories.length && React.createElement(Container, {
    "data-testid": "sub-category-list"
  }, React.createElement(List, null, React.createElement(List.Header, {
    titleAs: "h1"
  }, label || (category == null ? void 0 : category.label)), subcategories && subCateList.map(function (sub, index) {
    return React.createElement(List.Item, {
      key: sub.label,
      fontSize: 1,
      onClick: function onClick(ev) {
        handleClick({
          event: ev,
          subcategory: sub,
          type: 'subcategorytop'
        });
      },
      inset: true,
      testId: "sub-category-list-item-".concat(index),
      role: "link"
    }, React.createElement(Link, {
      "data-testid": "item-link",
      href: "".concat(basePath).concat(sub.path)
    }, sub.label));
  }), React.createElement(List.Action, {
    endIcon: React.createElement(Icon, {
      name: "ChevronRight"
    }),
    onClick: function onClick() {
      return handleClickSideBar();
    }
  }, "Ver todos")), React.createElement(Sidebar, {
    visible: visible,
    direction: "right",
    fullScreen: true
  }, React.createElement(Sidebar.Header, {
    icon: "back",
    variant: "primary",
    textAlign: "left",
    onClick: function onClick() {
      return setVisible(false);
    }
  }, category == null ? void 0 : category.label), React.createElement("nav", null, React.createElement(List, null, subCategoryList && subCategoryList.map(function (sub, index) {
    return React.createElement(List.Item, {
      key: sub.path,
      url: "".concat(basePath).concat(sub.path),
      fontSize: 1,
      onClick: function onClick(ev) {
        handleClick({
          event: ev,
          subcategory: sub,
          type: 'subcategorylist'
        });
      },
      testId: "sub-category-list-item-".concat(index)
    }, sub.label);
  })))));
}
process.env.NODE_ENV !== "production" ? SubCategoryList.propTypes = {
  data: shape({}).isRequired,
  "static": shape({
    basePath: string,
    show: number,
    spa: bool
  }).isRequired
} : void 0;
SubCategoryList.ssr = true;
SubCategoryList.dataSource = {
  category: {
    query: 'CategoryQuery'
  }
};
export default withLayoutProps(SubCategoryList);