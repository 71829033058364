import _objectDestructuringEmpty from "@babel/runtime/helpers/esm/objectDestructuringEmpty";
import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { shape, string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import { Text } from '@magalu/stereo-ui/atoms';
import { usePublisher } from '@magalu/mixer-publisher';
import { magaluCompanies } from '@magalu/mixer-utils';
import SellerScore from '../SellerScore/SellerScore';
import { Container, SellerDetailsStore } from './SellerStoreInfo.styles';
var SellerStoreInfo = function SellerStoreInfo(_ref) {
  var product = _ref.data.product,
    props = _extends({}, (_objectDestructuringEmpty(_ref["static"]), _ref["static"]));
  var seller = product.seller;
  var magaluCompany = magaluCompanies[seller == null ? void 0 : seller.id];
  var isMagaluCompany = !!magaluCompany;
  var isMagalu = seller.category === '1p';
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  React.useEffect(function () {
    if (!isMagaluCompany && !isMagalu) {
      publish('sellerdetails:impression', {
        seller: seller
      });
    }
  }, []);
  var onSellerDetailsClick = function onSellerDetailsClick() {
    publish('sellerdetailspdp:open:modal:click', {
      seller: seller
    });
    publish('sellershowdetail:open');
  };
  return !isMagalu && (seller == null ? void 0 : seller.id) && React.createElement(Container, _extends({
    "data-testid": "seller-modal-content"
  }, props), React.createElement(Text, {
    fontWeight: "bold",
    color: "attributeLabel",
    fontSize: 3,
    mb: 3
  }, "Informa\xE7\xF5es da Loja"), React.createElement(SellerScore, {
    "static": {
      isMagaluCompany: isMagaluCompany,
      seller: seller
    }
  }), React.createElement(SellerDetailsStore, {
    role: "button",
    fontSize: [2, 1],
    onClick: onSellerDetailsClick,
    "data-testid": "sellerDetailsStore-link",
    mx: [0, 0, 'auto']
  }, "Ver mais informa\xE7\xF5es da loja", React.createElement(Icon, {
    color: "background.magablu",
    name: "ChevronRight",
    ml: 2
  })));
};
SellerStoreInfo.defaultProps = {
  data: {
    product: {
      seller: {
        category: '',
        description: '',
        id: ''
      }
    }
  },
  "static": {}
};
process.env.NODE_ENV !== "production" ? SellerStoreInfo.propTypes = {
  data: shape({
    product: shape({
      seller: shape({
        category: string,
        description: string,
        id: string
      })
    })
  }),
  "static": shape({})
} : void 0;
SellerStoreInfo.ssr = true;
export default SellerStoreInfo;