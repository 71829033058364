import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import withLayoutProps from '../../../hocs/withLayoutProps';
import ProductLink from './components/ProductLink';
import SharedBag from './components/SharedBag';
import SocialMedia from './components/SocialMedia';
var ShareActions = function ShareActions(_ref) {
  var _channel$data$, _channel$data$$value, _structure$config;
  var data = _ref.data,
    StaticProps = _ref["static"],
    structure = _ref.structure;
  var channel = data.channel,
    product = data.product;
  var sectionSharedBag = StaticProps.sectionSharedBag,
    sectionSocial = StaticProps.sectionSocial,
    sectionUrl = StaticProps.sectionUrl;
  return React.createElement(Flex, {
    flexDirection: "column"
  }, React.createElement(Text, {
    fontSize: 2,
    fontWeight: "medium",
    mb: 3,
    mt: 2
  }, StaticProps.title), React.createElement(SharedBag, {
    sectionSharedBag: sectionSharedBag,
    product: product,
    storeNumericId: channel == null ? void 0 : (_channel$data$ = channel.data[1]) == null ? void 0 : (_channel$data$$value = _channel$data$.value) == null ? void 0 : _channel$data$$value.id,
    cookieDomain: structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.cookieDomain
  }), React.createElement(ProductLink, {
    sectionUrl: sectionUrl,
    structure: structure
  }), React.createElement(SocialMedia, {
    sectionUrl: sectionUrl,
    sectionSocial: sectionSocial
  }));
};
process.env.NODE_ENV !== "production" ? ShareActions.propTypes = {
  data: shape({
    channel: shape({
      data: arrayOf(shape({}))
    })
  }),
  "static": shape({
    sectionSharedBag: shape({
      buttonLabel: string,
      show: string,
      title: string,
      url: string
    }),
    sectionSocial: shape({
      buttons: arrayOf(shape({
        color: string,
        eventType: string,
        href: string,
        name: string
      })),
      title: string
    }),
    sectionUrl: shape({
      title: string,
      url: string
    }),
    title: string
  }),
  structure: shape({})
} : void 0;
ShareActions.defaultProps = {
  data: {
    channel: {}
  },
  "static": {
    sectionSharedBag: {
      buttonLabel: 'Adicionar na sacola pronta',
      show: 'false',
      title: 'Compartilhe produtos na sacola pronta',
      url: '#'
    },
    sectionSocial: {
      title: 'Compartilhe nas redes sociais'
    },
    sectionUrl: {
      downloadQRCode: 'Baixar QR code',
      title: 'Link do produto',
      url: '#'
    },
    title: 'Ferramentas do Divulgador'
  },
  structure: {}
};
ShareActions.ssr = false;
ShareActions.displayName = 'ShareActions';
export default withLayoutProps(ShareActions);