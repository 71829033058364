import { zipcodeMask } from '@magalu/stereo-ui';
export var getFormattedSellerAddress = function getFormattedSellerAddress() {
  var addressDetails = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _addressDetails$addre = addressDetails.address,
    addressInfo = _addressDetails$addre === void 0 ? {} : _addressDetails$addre;
  var address = addressInfo.address,
    number = addressInfo.number,
    city = addressInfo.city,
    state = addressInfo.state,
    district = addressInfo.district,
    zipcode = addressInfo.zipcode;
  var formattedAddress = [];
  if (address && number) {
    formattedAddress.push("".concat(address, ", ").concat(number));
  }
  if (city && state) {
    var formatedDistrict = district ? "".concat(district, " - ") : '';
    formattedAddress.push("".concat(formatedDistrict).concat(city, "/").concat(state));
  }
  if (zipcode) {
    formattedAddress.push(zipcodeMask(zipcode));
  }
  return formattedAddress;
};