import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled from 'styled-components';
import Strip from '@magalu/stereo-ui/atoms/Strip';
import { getPaletteColor, themeGet } from '@magalu/stereo-ui-styles/build/utils';
var Container = styled.header(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 75px;\n  background-color: ", ";\n  box-sizing: border-box;\n  padding: 0 10px;\n"])), function (props) {
  return getPaletteColor('background.primary')(props);
});
var ContainerLogo = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding: 19px 12px;\n"])));
var StripHeader = styled(Strip)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n  width: calc(100% - 20px);\n"])), function (props) {
  return themeGet('radii.small')(props);
}, function (props) {
  return themeGet('radii.small')(props);
});
export { Container, ContainerLogo, StripHeader };