import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useRef } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import QRCode from 'react-qr-code';
import Button from '@magalu/stereo-ui/atoms/Button';
import Box from '@magalu/stereo-ui/atoms/Box';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import Dialog from '@magalu/stereo-ui/atoms/Dialog';
import { usePublisher } from '@magalu/mixer-publisher';
import isValidStaticProp from '../../../../../commons/isValidStaticProp';
import { SharedActionsIcon } from './SocialMedia.styles';
var SocialMedia = function SocialMedia(_ref) {
  var sectionSocial = _ref.sectionSocial,
    sectionUrl = _ref.sectionUrl;
  var qrCodeRef = useRef(null);
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    openQRCodeModal = _useState2[0],
    setOpenQRCodeModal = _useState2[1];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var handleQRCodeDownload = function handleQRCodeDownload() {
    var svgData = new XMLSerializer().serializeToString(qrCodeRef.current);
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    var img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      var pngFile = canvas.toDataURL('image/png');
      var downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = "".concat(pngFile);
      downloadLink.click();
      setOpenQRCodeModal(false);
    };
    img.src = "data:image/svg+xml;base64,".concat(btoa(svgData));
  };
  var handleClick = function handleClick(event, _ref2) {
    var eventType = _ref2.eventType,
      href = _ref2.href;
    if (href === '#') {
      event.preventDefault();
      setOpenQRCodeModal(true);
    }
    publish(eventType);
  };
  return React.createElement(Box, {
    mb: 3
  }, React.createElement(Text, {
    fontSize: 1,
    fontWeight: "regular",
    mb: 3,
    mt: 2
  }, sectionSocial.title), React.createElement(Flex, null, Object.entries(sectionSocial.buttons).map(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      entry = _ref4[0],
      _ref4$ = _ref4[1],
      href = _ref4$.href,
      _ref4$$name = _ref4$.name,
      name = _ref4$$name === void 0 ? '' : _ref4$$name,
      color = _ref4$.color,
      eventType = _ref4$.eventType,
      _ref4$$iconColor = _ref4$.iconColor,
      iconColor = _ref4$$iconColor === void 0 ? '#fff' : _ref4$$iconColor,
      customColor = _ref4$.customColor;
    return isValidStaticProp(href) && React.createElement(Button, {
      key: "".concat(entry, "-").concat(name),
      as: "a",
      title: name,
      centered: false,
      color: color,
      customColor: customColor,
      "data-testid": "share-actions-".concat(name.toLowerCase(), "-button"),
      disabled: false,
      full: false,
      href: href,
      target: "_blank",
      rel: "noopener",
      onClick: function onClick(event) {
        return handleClick(event, {
          eventType: eventType,
          href: href
        });
      },
      variation: "fill",
      size: "auto",
      height: "52px",
      width: "52px",
      mr: 3
    }, React.createElement(SharedActionsIcon, {
      name: name,
      color: iconColor,
      width: 32,
      height: 32
    }));
  })), React.createElement(Dialog, {
    visible: openQRCodeModal,
    onClickOutside: function onClickOutside() {
      return setOpenQRCodeModal(false);
    },
    type: "alert",
    bg: "background.white",
    position: "center",
    showClose: true
  }, React.createElement(Text, {
    mt: "2",
    textAlign: "center"
  }, React.createElement(QRCode, {
    "data-testid": "share-actions-modal-qrcode",
    ref: qrCodeRef,
    value: sectionUrl.url
  })), React.createElement(Button, {
    "data-testid": "share-actions-btn-download-qrcode",
    onClick: handleQRCodeDownload,
    m: "10px 0 0",
    variation: "outline",
    full: true
  }, sectionUrl.downloadQRCode)));
};
process.env.NODE_ENV !== "production" ? SocialMedia.propTypes = {
  sectionSocial: shape({
    buttons: arrayOf(shape({
      color: string,
      eventType: string,
      href: string,
      name: string
    })),
    title: string
  }),
  sectionUrl: shape({
    downloadQRCode: string,
    title: string,
    url: string
  })
} : void 0;
SocialMedia.defaultProps = {
  sectionSocial: {
    title: 'Compartilhe nas redes sociais'
  },
  sectionUrl: {
    downloadQRCode: 'Baixar QR code',
    title: 'Link do produto',
    url: '#'
  }
};
SocialMedia.ssr = false;
SocialMedia.displayName = 'SocialMedia';
export default SocialMedia;