import { cleanHtml } from '@magalu/mixer-utils';
export var parseData = function parseData() {
  var _structure$route;
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var structure = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var hasStoreId = structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.storeId;
  var _data$url = data.url,
    url = _data$url === void 0 ? '' : _data$url,
    _data$title = data.title,
    title = _data$title === void 0 ? '' : _data$title,
    _data$description = data.description,
    description = _data$description === void 0 ? '' : _data$description,
    _data$domain = data.domain,
    domain = _data$domain === void 0 ? '' : _data$domain,
    _data$linksParameter = data.linksParameter,
    linksParameter = _data$linksParameter === void 0 ? '' : _data$linksParameter;
  var txtUrl = hasStoreId ? "".concat(domain).concat(url) : "".concat(domain, "/").concat(url);
  var txtTitle = encodeURIComponent(title);
  var txtDesc = encodeURIComponent(cleanHtml(description));
  var witLinksParameter = "".concat(txtUrl).concat(encodeURIComponent(linksParameter));
  return {
    urlFacebook: "https://www.facebook.com/sharer/sharer.php?u=".concat(witLinksParameter),
    urlMail: "mailto:?subject=".concat(txtTitle, "&body=").concat(txtDesc, "%20").concat(witLinksParameter),
    urlTwitter: "https://twitter.com/intent/tweet?text=".concat(txtTitle, "&url=").concat(witLinksParameter),
    urlWhatsApp: "https://wa.me/?text=".concat(witLinksParameter)
  };
};