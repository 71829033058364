import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
import styled, { css } from 'styled-components';
import Button from '@magalu/stereo-ui/atoms/Button';
import { themeGet } from '@magalu/stereo-ui-styles';
export var WidgetContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n  right: -390px;\n  top: 145px;\n  transition: all 0.3s ease-out;\n  z-index: 2;\n\n  ", "\n\n  ", "\n\n  ", "\n"])), function (_ref) {
  var device = _ref.device;
  return device === 'mobile' && css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      right: 0;\n    "])));
}, function (_ref2) {
  var device = _ref2.device;
  return device === 'desktop' && css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      top: auto;\n    "])));
}, function (_ref3) {
  var active = _ref3.active,
    device = _ref3.device;
  return active && device === 'desktop' && css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      right: 0;\n      transition: all 0.3s ease-in;\n      box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);\n    "])));
});
export var WidgetOverlay = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  height: 100%;\n  transition: all 0.3s ease-out;\n  width: 100%;\n\n  ", "\n\n  ", "\n"])), function (_ref4) {
  var active = _ref4.active;
  return active && css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n      bottom: 0;\n      left: 0;\n      position: fixed;\n      right: 0;\n      top: 0;\n      transition: all 0.3s ease-in;\n      z-index: 1;\n    "])));
}, function (_ref5) {
  var device = _ref5.device;
  return device === 'desktop' && css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n      display: none;\n    "])));
});
export var WidgetTriggerButton = styled(Button)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  border-radius: 0;\n  font-size: ", "px;\n  max-width: 40px;\n  height: 56px;\n  min-width: 0;\n  position: absolute;\n\n  ", "\n\n  ", "\n"])), function (props) {
  return themeGet('fontSizes.0')(props);
}, function (_ref6) {
  var device = _ref6.device;
  return device === 'mobile' && css(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n      border-bottom-left-radius: 4px;\n      border-top-left-radius: 4px;\n      right: 0;\n    "])));
}, function (_ref7) {
  var device = _ref7.device;
  return device === 'desktop' && css(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n      border-bottom-left-radius: 8px;\n      border-top-left-radius: 8px;\n      left: -40px;\n    "])));
});