import React from 'react';
import { node, shape } from 'prop-types';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Box } from './Row.styles';
var Row = function Row(_ref) {
  var StaticProps = _ref["static"],
    children = _ref.children;
  return React.createElement(Box, StaticProps, children);
};
process.env.NODE_ENV !== "production" ? Row.propTypes = {
  children: node.isRequired,
  "static": shape({})
} : void 0;
Row.defaultProps = {
  "static": {}
};
Row.ssr = true;
Row.displayName = 'Row';
export default withLayoutProps(Row);