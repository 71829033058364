import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import styled from 'styled-components';
import { getTextColor, themeGet } from '@magalu/stereo-ui-styles';
export var CountLabel = styled.p(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", "px;\n  font-weight: ", ";\n"])), function (props) {
  return getTextColor('scratched')(props);
}, function (props) {
  return themeGet('fontSizes.1')(props);
}, function (props) {
  return themeGet('fontWeights.bold')(props);
});