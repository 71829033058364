import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { Icon } from '@magalu/stereo-ui-icons';
import { Accordion, Box, HorizontalRule, Grid, Text } from '@magalu/stereo-ui/atoms';
import { Sidebar } from '@magalu/stereo-ui/organisms';
import React, { useState } from 'react';
import { bool, shape, string } from 'prop-types';
import { usePublisher } from '@magalu/mixer-publisher';
import { ScoreDetailsTexts } from './constants';
import { ShowDetails } from './ScoreDetails.styles';
var ScoreDetailsContent = function ScoreDetailsContent() {
  return React.createElement(Box, {
    "data-testid": "score-details-content",
    p: [3, 0]
  }, React.createElement(Text, {
    mt: 2,
    mb: 2,
    fontWeight: "regular"
  }, "A nota de reputa\xE7\xE3o refere-se a v\xE1rios crit\xE9rios na experi\xEAncia de compra oferecida aos clientes. Saiba o que levamos a considera\xE7\xE3o."), ScoreDetailsTexts.map(function (_ref) {
    var description = _ref.description,
      icon = _ref.icon,
      title = _ref.title;
    return React.createElement(Grid, {
      key: icon,
      gridAutoFlow: "column",
      gap: 2,
      py: 12
    }, React.createElement(Icon, {
      fill: "background.primary",
      name: icon,
      width: 33,
      height: 30
    }), React.createElement(Box, null, React.createElement(Text, {
      fontWeight: "bold",
      fontSize: [3, 2]
    }, title), React.createElement(Text, {
      mt: 2,
      fontSize: [1, 0]
    }, description)));
  }));
};
function ScoreDetails(_ref2) {
  var seller = _ref2.seller,
    _ref2$static = _ref2["static"],
    scoreDetailsTitle = _ref2$static.scoreDetailsTitle,
    scoreDetailsSidebar = _ref2$static.scoreDetailsSidebar,
    isMobile = _ref2.isMobile;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showScoreDetail = _useState2[0],
    setShowScoreDetails = _useState2[1];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var eventPublish = function eventPublish() {
    return publish('sellerdetails:click:about:rating', {
      seller: seller
    });
  };
  var handleShowDetails = function handleShowDetails() {
    eventPublish();
    setShowScoreDetails(true);
  };
  return isMobile ? React.createElement(React.Fragment, null, React.createElement(ShowDetails, {
    full: true,
    mt: 3,
    mb: 3,
    "data-testid": "showScoreDetails",
    onClick: handleShowDetails
  }, scoreDetailsTitle, React.createElement(Icon, {
    name: "ChevronRight",
    fill: "background.primary"
  })), React.createElement(Sidebar, {
    direction: "right",
    visible: showScoreDetail,
    fullScreen: true,
    title: scoreDetailsSidebar,
    "data-testid": "ScoreSidebar"
  }, React.createElement(Sidebar.Header, {
    startIcon: "ArrowBack",
    onClick: function onClick() {
      return setShowScoreDetails(false);
    }
  }, scoreDetailsSidebar), React.createElement(ScoreDetailsContent, null))) : React.createElement(Box, {
    "data-testid": "score-details-accordion",
    maxWidth: "340px"
  }, React.createElement(Box, {
    mt: 3
  }, React.createElement(HorizontalRule, null)), React.createElement(Accordion, {
    closeIcon: "ChevronUp",
    title: scoreDetailsTitle,
    open: showScoreDetail,
    onClick: function onClick() {
      setShowScoreDetails(!showScoreDetail);
      eventPublish();
    },
    fontWeight: "regular",
    openIcon: "ChevronDown"
  }, React.createElement(ScoreDetailsContent, null)), React.createElement(Box, {
    mb: 3
  }, React.createElement(HorizontalRule, null)));
}
ScoreDetails.defaultProps = {
  isMobile: false,
  seller: '',
  "static": {
    scoreDetailsSidebar: '',
    scoreDetailsTitle: ''
  }
};
process.env.NODE_ENV !== "production" ? ScoreDetails.propTypes = {
  isMobile: bool,
  seller: string,
  "static": shape({})
} : void 0;
export default ScoreDetails;