import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect } from 'react';
import { shape, arrayOf, func, string, number } from 'prop-types';
import List from '@magalu/stereo-ui/molecules/List';
import LinkButton from '@magalu/stereo-ui/atoms/Link';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../../hocs/withLayoutProps';
var FilterHierarchical = function FilterHierarchical(_ref) {
  var onClick = _ref.onClick,
    searchTerm = _ref.searchTerm,
    values = _ref.values,
    collapsedIn = _ref.collapsedIn,
    onClickCollapse = _ref.onClickCollapse;
  var _useState = useState(true),
    _useState2 = _slicedToArray(_useState, 2),
    trigger = _useState2[0],
    setTrigger = _useState2[1];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  useEffect(function () {
    setTrigger((values == null ? void 0 : values.length) >= collapsedIn);
  }, [JSON.stringify(values)]);
  var onHandleClick = function onHandleClick(e, filter) {
    e.preventDefault();
    publish('productlist:filter:click', _extends({
      searchTerm: searchTerm
    }, filter));
    onClick(e, filter);
  };
  var onHandleCollapseClick = function onHandleCollapseClick() {
    setTrigger(!trigger);
    if (!trigger) onClickCollapse();
  };
  return React.createElement(List, {
    scale: "small",
    disableGutters: true,
    dataSource: values,
    collapse: (values == null ? void 0 : values.length) >= collapsedIn,
    trigger: trigger,
    collapsedIn: (values == null ? void 0 : values.length) <= collapsedIn ? values == null ? void 0 : values.length : collapsedIn,
    renderItem: function renderItem(filter) {
      return React.createElement(List.Item, {
        key: "".concat(filter.id, "-").concat(filter.slug),
        fill: "background.primary",
        filterPosition: filter.position,
        fontSize: 2,
        textAlign: "initial",
        startIcon: filter.icon,
        as: filter.href && 'a',
        href: filter.href,
        inset: filter.inset,
        fontWeight: !filter.href && 'bold',
        onClick: function onClick(e) {
          return filter.href && onHandleClick(e, filter);
        }
      }, filter.label);
    },
    renderAction: (values == null ? void 0 : values.length) >= collapsedIn && React.createElement(List.Action, null, React.createElement(LinkButton, {
      color: "text.primary",
      fontWeight: "medium",
      fontSize: 2,
      px: 0,
      onClick: onHandleCollapseClick,
      "data-testid": "filter-action"
    }, trigger ? 'Ver todos' : 'Ver menos'))
  });
};
process.env.NODE_ENV !== "production" ? FilterHierarchical.propTypes = {
  collapsedIn: number,
  onClick: func.isRequired,
  onClickCollapse: func,
  searchTerm: string,
  values: arrayOf(shape({}))
} : void 0;
FilterHierarchical.defaultProps = {
  collapsedIn: 5,
  onClickCollapse: function onClickCollapse() {},
  searchTerm: '',
  values: []
};
FilterHierarchical.ssr = true;
export default withLayoutProps(FilterHierarchical);