export var ScoreDetailsTexts = [{
  description: 'Pedidos que foram despachados e entregues no prazo previsto.',
  icon: 'DeliveryDefault',
  title: 'Pedidos entregues no prazo'
}, {
  description: 'Total de cancelamentos de pedidos solicitados pelos clientes ou por essa loja por diferentes motivos: falta de peças, produto com avarias, produto diferente ou pelo recebimento fora do prazo.',
  icon: 'PackageAlert',
  title: 'Cancelamentos'
}, {
  description: 'Avalia o tempo que a loja leva para responder aos clientes nos canais de atendimento: Protocolo, Chat com o Cliente e Perguntas e Respostas',
  icon: 'CustomerContact',
  title: 'Agilidade no atendimento'
}];