import React from 'react';
import Logo from '@magalu/stereo-ui/atoms/Logo';
import { routePush } from '@magalu/mixer-utils';
import { Container, ContainerLogo, StripHeader } from './SimpleHeader.styles';
var SimpleHeader = function SimpleHeader() {
  return React.createElement(Container, null, React.createElement(ContainerLogo, {
    onClick: function onClick() {
      routePush({
        path: '/',
        spa: true
      });
    },
    "data-testid": "container-logo"
  }, React.createElement(Logo, {
    width: 137,
    height: 29
  })), React.createElement(StripHeader, {
    size: 6
  }));
};
export default SimpleHeader;