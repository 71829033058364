import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { shape, string, bool } from 'prop-types';
import DeliveryComponent from '@magalu/stereo-ui/organisms/SellerDelivery';
import { routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
var SellerDelivery = function SellerDelivery(_ref) {
  var product = _ref.data.product,
    _ref$static = _ref["static"],
    href = _ref$static.href,
    spa = _ref$static.spa,
    disableSellerClick = _ref$static.disableSellerClick,
    showDialog = _ref$static.showDialog,
    logoIconColor = _ref$static.logoIconColor;
  var _ref2 = product || {},
    seller = _ref2.seller;
  var path = "".concat(href).concat(seller == null ? void 0 : seller.id, "/");
  var props = {
    disableSellerClick: disableSellerClick,
    seller: _extends({}, seller, {
      href: path
    }),
    showDialog: showDialog
  };
  var onOffersClick = function onOffersClick(e) {
    e.preventDefault();
    routePush({
      path: path,
      spa: spa
    });
  };
  if (spa) {
    props.onOffersClick = onOffersClick;
  }
  return React.createElement(DeliveryComponent, _extends({
    logoIconColor: logoIconColor
  }, props));
};
process.env.NODE_ENV !== "production" ? SellerDelivery.propTypes = {
  data: shape({
    product: shape({})
  }),
  "static": shape({
    href: string,
    showDialog: bool,
    spa: bool
  })
} : void 0;
SellerDelivery.defaultProps = {
  data: {},
  "static": {
    href: '',
    logoIconColor: '',
    showDialog: false,
    spa: false
  }
};
SellerDelivery.dataSource = {
  product: {
    query: 'ProductQuery'
  }
};
SellerDelivery.ssr = true;
export default withLayoutProps(SellerDelivery);