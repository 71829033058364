import React from 'react';
import { shape, string } from 'prop-types';
import LogoParceiroMagalu from '@magalu/stereo-ui/atoms/LogoParceiroMagalu';
import { routePush } from '@magalu/mixer-utils';
import { Container, ContainerLogo } from './SimpleHeaderPMD.styles';
var SimpleHeaderPMD = function SimpleHeaderPMD(_ref) {
  var staticProps = _ref["static"];
  var lowerStoreName = (staticProps.storeName || '').toLowerCase();
  return React.createElement(Container, null, React.createElement(ContainerLogo, {
    onClick: function onClick() {
      routePush({
        path: "/magazine".concat(lowerStoreName, "/"),
        spa: true
      });
    },
    "data-testid": "container-logo"
  }, React.createElement(LogoParceiroMagalu, {
    name: lowerStoreName
  })));
};
process.env.NODE_ENV !== "production" ? SimpleHeaderPMD.propTypes = {
  "static": shape({
    storeName: string
  })
} : void 0;
SimpleHeaderPMD.defaultProps = {
  "static": {
    storeName: ''
  }
};
SimpleHeaderPMD.ssr = true;
SimpleHeaderPMD.displayName = 'SimpleHeaderPMD';
export default SimpleHeaderPMD;