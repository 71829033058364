import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["data", "storeName", "structure"];
import React, { useState } from 'react';
import { func, shape, arrayOf, string } from 'prop-types';
import { Box } from '@magalu/stereo-ui/atoms';
import { List } from '@magalu/stereo-ui/molecules';
import withLayoutProps from '../../../hocs/withLayoutProps';
import SidebarHeading from './SidebarHeading';
import { Container, Content, LogoutLink } from './SidebarMenuPMD.styles';
function SidebarMenuPMD(_ref) {
  var _structure$cookies, _structure$cookies2, _structure$config, _structure$config$car, _structure$config2, _categoryList$list, _categoryList$list2, _structure$cookies3, _structure$cookies4, _structure$cookies5, _structure$cookies5$p, _structure$cookies6, _structure$cookies6$a;
  var data = _ref.data,
    storeName = _ref.storeName,
    structure = _ref.structure,
    props = _objectWithoutProperties(_ref, _excluded);
  var _ref2 = data || {},
    categoryList = _ref2.categoryList,
    header = _ref2.header,
    account = _ref2.account;
  var onCollapsedClick = props.onCollapsedClick,
    onMenuItemClick = props.onMenuItemClick,
    onMenuHeaderClick = props.onMenuHeaderClick;
  var _useState = useState(true),
    _useState2 = _slicedToArray(_useState, 2),
    collapsed = _useState2[0],
    setCollapsed = _useState2[1];
  var handleCollapsed = function handleCollapsed() {
    onCollapsedClick();
    setCollapsed(!collapsed);
  };
  return React.createElement(Container, {
    "data-testid": "sidebarmenu-pmd-container"
  }, header && React.createElement(SidebarHeading, {
    accountData: structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : _structure$cookies.accountData,
    data: header,
    storeName: storeName,
    onMenuHeaderClick: onMenuHeaderClick,
    promoterData: structure == null ? void 0 : (_structure$cookies2 = structure.cookies) == null ? void 0 : _structure$cookies2.promoterData,
    theme: structure.theme,
    cartUrl: structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : (_structure$config$car = _structure$config.cartUrl) == null ? void 0 : _structure$config$car["default"],
    domain: structure == null ? void 0 : (_structure$config2 = structure.config) == null ? void 0 : _structure$config2.domain
  }), React.createElement(Content, null, (categoryList == null ? void 0 : (_categoryList$list = categoryList.list) == null ? void 0 : _categoryList$list.length) && React.createElement(Box, {
    bg: "white",
    borderRadius: 4
  }, React.createElement(List.Collapse, {
    trigger: collapsed,
    collapsedIn: categoryList == null ? void 0 : categoryList.show,
    renderAction: collapsed && React.createElement(List.Action, {
      endIcon: "ChevronDown",
      fontWeight: "medium",
      onClick: handleCollapsed
    }, categoryList == null ? void 0 : categoryList.collapseText),
    divided: true,
    "data-testid": "category-list"
  }, categoryList == null ? void 0 : (_categoryList$list2 = categoryList.list) == null ? void 0 : _categoryList$list2.map(function (item) {
    return React.createElement(List.Item, {
      key: item == null ? void 0 : item.id,
      as: "a",
      href: item == null ? void 0 : item.url,
      rel: item == null ? void 0 : item.rel,
      startIcon: "Category".concat(item == null ? void 0 : item.id),
      onClick: function onClick(e) {
        return onMenuItemClick(e, item, 'departamento:');
      },
      fill: "background.primary"
    }, item == null ? void 0 : item.label);
  }))), ((structure == null ? void 0 : (_structure$cookies3 = structure.cookies) == null ? void 0 : _structure$cookies3.accountData) || (structure == null ? void 0 : (_structure$cookies4 = structure.cookies) == null ? void 0 : _structure$cookies4.promoterData)) && React.createElement(LogoutLink, {
    "data-testid": "logout-link",
    href: account == null ? void 0 : account.signOutUrl,
    bg: "primary",
    underlineOnHover: false,
    textAlign: "center",
    underline: true,
    mt: 4,
    py: 3
  }, React.createElement("span", null, "N\xE3o \xE9 ".concat((structure == null ? void 0 : (_structure$cookies5 = structure.cookies) == null ? void 0 : (_structure$cookies5$p = _structure$cookies5.promoterData) == null ? void 0 : _structure$cookies5$p.name) || (structure == null ? void 0 : (_structure$cookies6 = structure.cookies) == null ? void 0 : (_structure$cookies6$a = _structure$cookies6.accountData) == null ? void 0 : _structure$cookies6$a.name), "? Sair")))));
}
SidebarMenuPMD.defaultProps = {
  storeName: '',
  structure: {}
};
process.env.NODE_ENV !== "production" ? SidebarMenuPMD.propTypes = {
  data: shape({
    categoryList: shape({
      collapseText: string.isRequired,
      list: arrayOf(shape({
        id: string.isRequired,
        label: string.isRequired,
        url: string
      })).isRequired,
      orderBy: arrayOf(string)
    }),
    header: shape({
      helpers: arrayOf(shape({
        id: string.isRequired,
        label: string.isRequired,
        url: string.isRequired
      })).isRequired,
      id: string,
      label: string.isRequired,
      loggedLabel: string,
      loggedUrl: string,
      url: string.isRequired
    }),
    showcaseLinks: arrayOf(shape({}))
  }).isRequired,
  onCollapsedClick: func.isRequired,
  onMenuHeaderClick: func.isRequired,
  onMenuItemClick: func.isRequired,
  storeName: string,
  structure: shape({})
} : void 0;
SidebarMenuPMD.ssr = true;
SidebarMenuPMD.displayName = 'SidebarMenuPMD';
export default withLayoutProps(SidebarMenuPMD);