import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect } from 'react';
import { shape, bool, string } from 'prop-types';
import CopyToClipboard from '@magalu/stereo-ui/molecules/CopyToClipboard';
import Box from '@magalu/stereo-ui/atoms/Box';
import Text from '@magalu/stereo-ui/atoms/Text';
import Checkbox from '@magalu/stereo-ui/atoms/Checkbox';
import Radio from '@magalu/stereo-ui/atoms/Radio';
import { getCookie } from '@magalu/mixer-utils';
import useShortenLink from '../../../../../hooks/useShortenLink';
import { Grid } from './ProductLink.styles';
import setShortLink from './hooks/setShortLink';
var ProductLink = function ProductLink(_ref) {
  var _shortenedConfigs$mag, _shortenedConfigs$one, _window2, _window2$location;
  var sectionUrl = _ref.sectionUrl,
    structure = _ref.structure;
  var shareUrlCookie = getCookie('toggle_shareurl_pmd_v2') || '';
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isShortUrl = _useState2[0],
    setIsShortUrl = _useState2[1];
  var _useState3 = useState('store'),
    _useState4 = _slicedToArray(_useState3, 2),
    typeUrl = _useState4[0],
    setTypeUrl = _useState4[1];
  var _useState5 = useState(sectionUrl.url),
    _useState6 = _slicedToArray(_useState5, 2),
    urlToCopy = _useState6[0],
    setUrlToCopy = _useState6[1];
  var isWindowNotUndefined = typeof window !== 'undefined';
  var shouldShowShortenRadios = (sectionUrl == null ? void 0 : sectionUrl.allowSuperAppLink) && shareUrlCookie.includes('app');
  var shouldShowShortenCheckbox = shareUrlCookie.includes('shorten') && typeUrl === 'store';
  var _structure$asPath = structure.asPath,
    asPath = _structure$asPath === void 0 ? '' : _structure$asPath,
    _structure$basePath = structure.basePath,
    basePath = _structure$basePath === void 0 ? '' : _structure$basePath,
    _structure$config = structure.config,
    config = _structure$config === void 0 ? {} : _structure$config;
  var toShortenUrls = {
    desktop: "".concat(config.domain).concat(asPath),
    magaly: "".concat(config.domain).concat(asPath),
    onelink: "https://m.magazineluiza.com.br".concat(asPath == null ? void 0 : asPath.split(basePath).join(''))
  };
  var resetShortenSearch = function resetShortenSearch() {
    var _window$sessionStorag, _window$sessionStorag2;
    setUrlToCopy(sectionUrl.url);
    setTypeUrl('store');
    setIsShortUrl(false);
    (_window$sessionStorag = window.sessionStorage) == null ? void 0 : _window$sessionStorag.setItem('shorten_magaly', null);
    (_window$sessionStorag2 = window.sessionStorage) == null ? void 0 : _window$sessionStorag2.setItem('shorten_onelink', null);
  };
  var shortenedConfigs = {
    magaly: useShortenLink('shortenlink-magaly', {
      link: toShortenUrls.magaly
    }),
    onelink: useShortenLink('shortenlink-onelink', {
      addPartnerId: true,
      desktopLink: toShortenUrls.desktop,
      link: toShortenUrls.onelink
    })
  };
  setShortLink(shortenedConfigs == null ? void 0 : (_shortenedConfigs$mag = shortenedConfigs.magaly) == null ? void 0 : _shortenedConfigs$mag.shortLink, 'shorten_magaly', setUrlToCopy);
  setShortLink(shortenedConfigs == null ? void 0 : (_shortenedConfigs$one = shortenedConfigs.onelink) == null ? void 0 : _shortenedConfigs$one.shortLink, 'shorten_onelink', setUrlToCopy);
  useEffect(function () {
    sessionStorage.setItem('shorten_magaly', null);
    sessionStorage.setItem('shorten_onelink', null);
  }, []);
  useEffect(function () {
    var _window, _window$location;
    return isWindowNotUndefined && ((_window = window) == null ? void 0 : (_window$location = _window.location) == null ? void 0 : _window$location.href) && resetShortenSearch();
  }, [isWindowNotUndefined && ((_window2 = window) == null ? void 0 : (_window2$location = _window2.location) == null ? void 0 : _window2$location.href)]);
  useEffect(function () {
    var type;
    if (isShortUrl && typeUrl === 'store') type = 'magaly';
    if (typeUrl === 'app') type = 'onelink';
    if (type) {
      var url = sessionStorage.getItem("shorten_".concat(type));
      if (!url || url === 'null' || url === 'undefined') shortenedConfigs[type].handleShortenLink();else setUrlToCopy(url);
    } else {
      setUrlToCopy(sectionUrl.url);
    }
  }, [isShortUrl, typeUrl]);
  return React.createElement(Box, null, React.createElement(Text, {
    fontSize: 1,
    fontWeight: "regular",
    mb: 3,
    mt: 2
  }, sectionUrl.title), shouldShowShortenRadios && React.createElement(Grid, null, React.createElement(Radio, {
    label: "Minha loja",
    value: "store",
    checked: typeUrl === 'store',
    onChange: function onChange(e) {
      return setTypeUrl(e.target.value);
    }
  }), React.createElement(Radio, {
    label: "App do Magalu",
    value: "app",
    checked: typeUrl === 'app',
    onChange: function onChange(e) {
      return setTypeUrl(e.target.value);
    }
  })), React.createElement(Box, {
    mb: 3
  }, React.createElement(CopyToClipboard, {
    value: urlToCopy
  })), shouldShowShortenCheckbox && React.createElement(Checkbox, {
    checked: isShortUrl,
    onChange: function onChange(e) {
      setIsShortUrl(e.target.checked);
    },
    fontSize: 1,
    mb: 3
  }, "Link curto"));
};
process.env.NODE_ENV !== "production" ? ProductLink.propTypes = {
  sectionUrl: shape({
    allowSuperAppLink: bool,
    title: string,
    url: string
  }),
  structure: shape({})
} : void 0;
ProductLink.defaultProps = {
  sectionUrl: {
    allowSuperAppLink: false,
    title: 'Link do produto',
    url: '#'
  },
  structure: {}
};
ProductLink.ssr = false;
ProductLink.displayName = 'ProductLink';
export default ProductLink;