import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect, useState } from 'react';
import { shape, bool } from 'prop-types';
import DeliveryComponent from '@magalu/stereo-ui/organisms/SellerDelivery';
import { usePublisher } from '@magalu/mixer-publisher';
import { internationalPartners } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { ModalContent } from './ModalContent';
var SellerDetails = function SellerDetails(_ref) {
  var product = _ref.data.product,
    _ref$static = _ref["static"],
    _ref$static$disableSe = _ref$static.disableSellerClick,
    disableSellerClick = _ref$static$disableSe === void 0 ? false : _ref$static$disableSe,
    href = _ref$static.href,
    logoIconColor = _ref$static.logoIconColor,
    scoreDetailsTitle = _ref$static.scoreDetailsTitle,
    scoreDetailsSidebar = _ref$static.scoreDetailsSidebar,
    showDialog = _ref$static.showDialog,
    spa = _ref$static.spa;
  var eventName = 'sellershowdetail:open';
  var seller = product.seller,
    variationId = product.variationId;
  var _usePublisher = usePublisher(),
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe,
    publish = _usePublisher.publish;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    showSellerDetailContent = _useState2[0],
    setShowSellerDetailContent = _useState2[1];
  var handleShowDialog = function handleShowDialog() {
    return setShowSellerDetailContent(true);
  };
  var handleCloseDialog = function handleCloseDialog() {
    return setShowSellerDetailContent(false);
  };
  useEffect(function () {
    subscribe(eventName, handleShowDialog);
    return function () {
      unsubscribe(eventName, handleShowDialog);
    };
  });
  var internationalPartner = internationalPartners[seller.id];
  var internationalPartnerProps = internationalPartner ? {
    internationalPartner: true,
    partnerColor: internationalPartner.color,
    partnerLogo: internationalPartner.logo
  } : {};
  var props = _extends({
    disableSellerClick: disableSellerClick,
    href: href,
    onCloseModalContent: handleCloseDialog,
    product: product,
    scoreDetailsSidebar: scoreDetailsSidebar,
    scoreDetailsTitle: scoreDetailsTitle,
    seller: seller,
    showDialog: showDialog,
    showSellerDetailContent: showSellerDetailContent,
    spa: spa
  }, internationalPartnerProps);
  var handleSellerClick = function handleSellerClick() {
    publish('sellerdetails:open:modal:click', {
      seller: seller,
      variationId: variationId
    });
    publish(eventName);
  };
  return React.createElement(DeliveryComponent, _extends({
    logoIconColor: logoIconColor,
    onSellerClick: handleSellerClick,
    modal: {
      content: React.createElement(ModalContent, props),
      title: 'Informações da loja parceira',
      titleStyle: {
        color: 'attributeLabel',
        fontWeight: 'bold',
        pl: 0
      }
    }
  }, props));
};
process.env.NODE_ENV !== "production" ? SellerDetails.propTypes = {
  data: shape({
    product: shape({})
  }),
  "static": shape({
    showDialog: bool
  })
} : void 0;
SellerDetails.defaultProps = {
  data: {
    product: {}
  },
  "static": {
    href: '',
    logoIconColor: '',
    scoreDetailsSidebar: '',
    scoreDetailsTitle: '',
    showDialog: false,
    spa: false
  }
};
SellerDetails.dataSource = {
  product: {
    query: 'ProductQuery'
  }
};
SellerDetails.ssr = true;
export default withLayoutProps(SellerDetails);