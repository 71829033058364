import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/esm/extends";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
var _excluded = ["hidden"];
import React, { useMemo } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Chip from '@magalu/stereo-ui/atoms/Chip';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import currencyFormatter from '@magalu/stereo-ui-utils/currencyFormatter';
import { Icon } from '@magalu/stereo-ui-icons';
import { usePublisher } from '@magalu/mixer-publisher';
import { buildFilterUrl, composite, parsePriceFilter, urlCleanSelectedFilters, routePush } from '@magalu/mixer-utils';
import { Button, Title } from './SelectedFilters.styles';
export function getUrlFilterTypes(structure) {
  var _structure$route;
  var filters = (structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.filters) || [];
  return filters.reduce(function (types, filter) {
    return filter.origin === 'url' ? [].concat(_toConsumableArray(types), [filter.type]) : types;
  }, []);
}
export function review(_ref) {
  var data = _ref.data;
  if ((data == null ? void 0 : data.type) !== 'review') return data;
  return [{
    label: (data == null ? void 0 : data.selectedMin) && "".concat(data.selectedMin, " estrela").concat(data.selectedMin > 1 ? 's' : ''),
    selected: !!(data == null ? void 0 : data.selectedMin),
    slug: data == null ? void 0 : data.selectedMin,
    type: data == null ? void 0 : data.slug,
    unique: true
  }];
}
function basic(_ref2) {
  var _data$values;
  var data = _ref2.data;
  return (data == null ? void 0 : (_data$values = data.values) == null ? void 0 : _data$values.map(function (value) {
    return _extends({}, value, {
      type: data == null ? void 0 : data.slug
    });
  })) || data;
}
function getPriceRangeLabel(_ref3) {
  var selectedMin = _ref3.selectedMin,
    selectedMax = _ref3.selectedMax;
  if (selectedMin && selectedMax) {
    return "De ".concat(currencyFormatter({
      value: parsePriceFilter(selectedMin)
    }), " at\xE9 ").concat(currencyFormatter({
      value: parsePriceFilter(selectedMax)
    }));
  }
  if (selectedMin) {
    return "A partir de ".concat(currencyFormatter({
      value: parsePriceFilter(selectedMin)
    }));
  }
  if (selectedMax) {
    return "At\xE9 ".concat(currencyFormatter({
      value: parsePriceFilter(selectedMax)
    }));
  }
  return '';
}
function price(_ref4) {
  var data = _ref4.data;
  if ((data == null ? void 0 : data.type) !== 'price') return data;
  return [{
    label: getPriceRangeLabel(data),
    selected: !!(data == null ? void 0 : data.selectedMin) || !!(data == null ? void 0 : data.selectedMax),
    selectedMax: data == null ? void 0 : data.selectedMax,
    selectedMin: data == null ? void 0 : data.selectedMin,
    slug: (data == null ? void 0 : data.selectedMin) || (data == null ? void 0 : data.selectedMax),
    type: data == null ? void 0 : data.slug
  }];
}
var plugins = [basic, review, price];
function CloseCircleFilled(_ref5) {
  var onClick = _ref5.onClick;
  return React.createElement(Icon, {
    name: "CloseCircleFilled",
    width: 16,
    height: 16,
    color: "light",
    cursor: "pointer",
    onClick: onClick,
    "data-testid": "CloseCircleFilled"
  });
}
process.env.NODE_ENV !== "production" ? CloseCircleFilled.propTypes = {
  onClick: func.isRequired
} : void 0;
function SelectedFilters(_ref6) {
  var staticProps = _ref6["static"],
    data = _ref6.data,
    structure = _ref6.structure;
  var urlFilters = getUrlFilterTypes(structure);
  var _staticProps$title = staticProps.title,
    title = _staticProps$title === void 0 ? 'Filtros Selecionados' : _staticProps$title;
  var _ref7 = (data == null ? void 0 : data.search) || {},
    searchFilters = _ref7.filters;
  var filters = useMemo(function () {
    return searchFilters == null ? void 0 : searchFilters.reduce(function (acc, filter) {
      return [].concat(_toConsumableArray(acc), _toConsumableArray(composite.apply(void 0, plugins)({
        data: filter
      })));
    }, []);
  }, [searchFilters]);
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var query = structure.query;
  var getSelectedCategoryFilter = function getSelectedCategoryFilter(filter) {
    var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'category';
    var selectedSubcategoryFilter = ((filter == null ? void 0 : filter.values) || []).find(function (_ref8) {
      var selected = _ref8.selected;
      return selected;
    });
    if (selectedSubcategoryFilter) return getSelectedCategoryFilter(selectedSubcategoryFilter, 'subcategory');
    if (type !== 'category') return _extends({}, filter, {
      type: type
    });
    return null;
  };
  var selectedFilters = filters == null ? void 0 : filters.reduce(function (acc, filter) {
    if ((filter == null ? void 0 : filter.selected) && !urlFilters.includes(filter.type)) {
      if (filter.type === 'category') {
        var selectedSubcategoryFilter = getSelectedCategoryFilter(filter);
        return [].concat(_toConsumableArray(acc), [_extends({}, filter, {
          hidden: Boolean(selectedSubcategoryFilter)
        }), selectedSubcategoryFilter]);
      }
      return [].concat(_toConsumableArray(acc), [filter]);
    }
    return acc;
  }, []).filter(Boolean);
  var handleRemoveFilter = function handleRemoveFilter() {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var type = item.type,
      id = item.id,
      slug = item.slug,
      selected = item.selected,
      unique = item.unique,
      selectedMax = item.selectedMax,
      selectedMin = item.selectedMin;
    publish('productlist:filter:remove', item);
    var path = buildFilterUrl({
      max: selectedMax,
      min: selectedMin,
      query: query,
      selected: selected,
      structure: structure,
      type: type,
      unique: unique,
      value: id || slug
    });
    routePush({
      path: path,
      spa: true
    });
  };
  var handleClearFilters = function handleClearFilters() {
    publish('productlist:filter:clean');
    var path = urlCleanSelectedFilters(selectedFilters, query, structure);
    routePush({
      path: path,
      spa: true
    });
  };
  if (selectedFilters == null ? void 0 : selectedFilters.length) {
    return React.createElement(Box, {
      width: [1],
      py: 3,
      pr: [2],
      pb: [4],
      border: 0,
      borderLeft: "none"
    }, React.createElement(Flex, {
      p: [2],
      justifyContent: "space-between",
      flexDirection: "columns"
    }, React.createElement(Title, {
      "data-testid": "selected-filters"
    }, title), React.createElement(Button, {
      "data-testid": "clean-filter",
      onClick: handleClearFilters
    }, "Limpar filtros")), React.createElement(Flex, {
      mx: [2],
      flexGrow: 3,
      flexWrap: "wrap"
    }, selectedFilters.map(function (_ref9, position) {
      var hidden = _ref9.hidden,
        filter = _objectWithoutProperties(_ref9, _excluded);
      return !hidden && React.createElement(Chip, {
        key: "".concat(filter.type, "-").concat(filter.slug),
        type: "checkbox",
        clickable: true,
        autoSize: true,
        bg: "secondary",
        as: "div",
        color: "base",
        m: 1,
        endIcon: React.createElement(CloseCircleFilled, {
          onClick: function onClick() {
            return handleRemoveFilter(_extends({}, filter, {
              position: position
            }));
          }
        })
      }, filter.label);
    })));
  }
  return null;
}
SelectedFilters.defaultProps = {
  data: {},
  "static": {},
  structure: {}
};
process.env.NODE_ENV !== "production" ? SelectedFilters.propTypes = {
  data: shape({
    search: shape({
      filters: arrayOf(shape({
        values: arrayOf(shape({
          label: string,
          selected: bool,
          slug: string
        }))
      }))
    })
  }),
  "static": shape({
    title: string
  }),
  structure: shape({})
} : void 0;
export default SelectedFilters;