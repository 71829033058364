import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _extends from "@babel/runtime/helpers/esm/extends";
import React, { useEffect, useState } from 'react';
import { shape } from 'prop-types';
import RatingLine from '@magalu/stereo-ui/molecules/RatingLine';
import Box from '@magalu/stereo-ui/atoms/Box';
import Text from '@magalu/stereo-ui/atoms/Text';
import List from '@magalu/stereo-ui/molecules/List';
import { usePublisher } from '@magalu/mixer-publisher';
import { getCurrentPath, routePush } from '@magalu/mixer-utils';
var SelectByRating = function SelectByRating(_ref) {
  var staticProps = _ref["static"],
    data = _ref.data,
    structure = _ref.structure;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var title = staticProps.title;
  var _ref2 = data.productRating || {},
    _ref2$reviewsByRating = _ref2.reviewsByRating,
    reviewsByRating = _ref2$reviewsByRating === void 0 ? [] : _ref2$reviewsByRating,
    _ref2$general = _ref2.general,
    general = _ref2$general === void 0 ? {} : _ref2$general;
  var _ref3 = general || {},
    reviewCount = _ref3.reviewCount;
  var _ref4 = (structure == null ? void 0 : structure.route) || {},
    _ref4$filters = _ref4.filters,
    filters = _ref4$filters === void 0 ? [] : _ref4$filters;
  var reviewsByRatingData = reviewsByRating.sort(function (a, b) {
    return b.rating - a.rating;
  }).map(function (ratingReview) {
    var rating = ratingReview.rating,
      total = ratingReview.total;
    var rate = new Map([[1, 'ONE_STAR'], [2, 'TWO_STARS'], [3, 'THREE_STARS'], [4, 'FOUR_STARS'], [5, 'FIVE_STARS']]);
    return {
      href: "rating---".concat(rate.get(rating)),
      key: rating,
      selected: false,
      total: total,
      type: rate.get(rating),
      value: rating
    };
  });
  var updatedReviewsByRatingData = reviewsByRatingData.map(function (item) {
    var _rating$;
    var rating = filters.filter(function (filter) {
      return filter.type === 'rating';
    }).map(function (filter) {
      return {
        type: filter.type,
        value: filter.value
      };
    });
    if (item.type === ((_rating$ = rating[0]) == null ? void 0 : _rating$.value)) {
      return _extends({}, item, {
        selected: true
      });
    }
    return item;
  });
  var _useState = useState(updatedReviewsByRatingData),
    _useState2 = _slicedToArray(_useState, 2),
    options = _useState2[0],
    setOptions = _useState2[1];
  useEffect(function () {
    setOptions(updatedReviewsByRatingData);
  }, [filters]);
  var handleSubmitFilter = function handleSubmitFilter(filter, type) {
    var _rating$2;
    var rating = filters.filter(function (item) {
      return item.type === 'rating';
    }).map(function (item) {
      return {
        type: item.type,
        value: item.value
      };
    });
    if (((_rating$2 = rating[0]) == null ? void 0 : _rating$2.value) !== type) {
      var path = getCurrentPath({
        filters: filter.href,
        page: 1
      });
      routePush({
        path: path,
        spa: true
      });
    }
  };
  var _onChange = function onChange(filter, total, type) {
    if (total !== 0) {
      var newValue = options.map(function (item) {
        var same = item.value === filter.value;
        return _extends({}, item, {
          selected: !!same
        });
      });
      setOptions(newValue);
      handleSubmitFilter(filter, type);
      publish('review:filter:click', type, {
        data: data,
        structure: structure
      });
    }
  };
  return !!(reviewsByRating == null ? void 0 : reviewsByRating.length) && !!reviewCount && React.createElement(Box, {
    bg: "background.white",
    "data-testid": "select-by-rating-container"
  }, React.createElement(Text, {
    pb: 3,
    fontWeight: "medium",
    fontSize: 2
  }, title), React.createElement(List, {
    disableGutters: true,
    scale: "small",
    dataSource: options,
    renderItem: function renderItem(option) {
      return React.createElement(List.Radio, {
        as: "span",
        p: 3,
        key: option == null ? void 0 : option.key,
        value: option == null ? void 0 : option.value,
        selected: option == null ? void 0 : option.selected,
        onChange: function onChange() {
          return _onChange(option, option == null ? void 0 : option.total, option == null ? void 0 : option.type);
        },
        fontSize: 2,
        filterLabel: option == null ? void 0 : option.value,
        href: option == null ? void 0 : option.href,
        "data-testid": "radio-button"
      }, React.createElement(RatingLine, {
        as: "div",
        count: (option == null ? void 0 : option.total) <= 999 ? "".concat(option == null ? void 0 : option.total) : '+999',
        id: option == null ? void 0 : option.value,
        max: reviewCount,
        score: option == null ? void 0 : option.total,
        starColor: "#FFCC03",
        lineColor: "#0086ff",
        fontweightScore: "bold",
        width: 90,
        widthProgress: "186px",
        countColor: "black",
        columnGap: 14,
        starSize: 20,
        heightProgress: "8px",
        scoreFontSize: 2,
        pointerNone: true
      }));
    },
    name: "list-radio"
  }));
};
SelectByRating.defaultProps = {
  data: {
    productRating: {
      reviewsByRating: []
    }
  },
  "static": {
    isMobile: false,
    reviewOptions: [],
    title: ''
  },
  structure: {}
};
process.env.NODE_ENV !== "production" ? SelectByRating.propTypes = {
  data: shape({}),
  "static": shape({}),
  structure: {}
} : void 0;
SelectByRating.ssr = true;
export default SelectByRating;