import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import { layout, typography } from 'styled-system';
import BaseButton from '@magalu/stereo-ui/atoms/Button';
import Text from '@magalu/stereo-ui/atoms/Text';
import { color, getTextColor } from '@magalu/stereo-ui-styles';
export var Button = styled(BaseButton).attrs(function (props) {
  return _extends({
    bg: 'unset',
    color: 'text.primary',
    fontSize: 2,
    fontWeight: 'medium',
    height: ['auto'],
    lineHeight: 'display',
    noHover: true,
    width: ['auto']
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &:hover {\n    text-decoration: underline;\n    color: ", ";\n  }\n  ", "\n  ", "\n  ", "\n"])), function (props) {
  return getTextColor('primary')(props);
}, color, typography, layout);
export var Title = styled(Text).attrs(function (props) {
  return _extends({
    fontSize: 2,
    fontWeight: 'bold',
    lineHeight: 'display'
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));