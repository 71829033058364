import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { node, oneOfType, shape, string } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import { LuContentContext, LuDetail } from '@magalu/stereo-ui/templates/LuContent';
import RichDescription from '../RichDescription';
import { ShowMore } from './SupportText.styles';
var defaultImages = {
  blogImage: 'https://wx.mlcdn.com.br/site/shared/lu-content/blog-da-lu.png',
  luExplainImage: 'https://wx.mlcdn.com.br/site/shared/lu-content/lu-explain.png',
  luImage: 'https://wx.mlcdn.com.br/site/shared/lu-content/lu-explains-new.png'
};
var SupportText = function SupportText(_ref) {
  var staticProps = _ref["static"];
  var _ref2 = staticProps || {},
    content = _ref2.content,
    preview = _ref2.preview,
    title = _ref2.title,
    _ref2$direction = _ref2.direction,
    direction = _ref2$direction === void 0 ? 'center' : _ref2$direction,
    _ref2$images = _ref2.images,
    images = _ref2$images === void 0 ? defaultImages : _ref2$images;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    openDialog = _useState2[0],
    setOpenDialog = _useState2[1];
  var handleClickClose = function handleClickClose() {
    return setOpenDialog(false);
  };
  var handleClickOpen = function handleClickOpen() {
    return setOpenDialog(true);
  };
  return preview && React.createElement(Box, {
    marginY: 4,
    paddingY: 4,
    paddingX: 2,
    borderTopWidth: 1,
    borderTopColor: "whisper",
    borderTopStyle: "solid"
  }, React.createElement(RichDescription, {
    "static": {
      fontSize: 2,
      lineHeight: '1.3em',
      richContent: preview
    }
  }), content && React.createElement(React.Fragment, null, React.createElement(ShowMore, {
    as: "a",
    role: "button",
    onClick: handleClickOpen,
    "data-testid": "support-text-link"
  }, "Continuar lendo..."), React.createElement(LuContentContext, null, React.createElement(LuDetail, {
    visible: openDialog,
    onBackButtonClick: handleClickClose,
    images: images,
    direction: direction,
    content: content,
    subtitle: title
  }))));
};
process.env.NODE_ENV !== "production" ? SupportText.propTypes = {
  "static": shape({
    content: oneOfType([node, string]),
    direction: string,
    preview: oneOfType([node, string]),
    title: string
  })
} : void 0;
SupportText.defaultProps = {
  "static": {}
};
SupportText.ssr = true;
export default SupportText;