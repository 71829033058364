import React from 'react';
import { bool, shape, string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import { Box, Text, Grid, Flex } from '@magalu/stereo-ui/atoms';
import { routePush, magaluCompanies, isInternationalPrice } from '@magalu/mixer-utils';
import cnpjMask from '../../../../commons/masks/cnpjMask';
import SellerScore from '../../SellerScore/SellerScore';
import ScoreDetails from '../ScoreDetails/ScoreDetails';
import { SellerGuaranteeText } from '../GuaranteeText';
import { usePublisher } from '@magalu/mixer-publisher';
import { getFormattedSellerAddress } from './ModalContent.utils';
import { Offers, DataInfo, DataTitle, Hr } from './ModalContent.styles';
function ModalContent(_ref) {
  var _seller$details;
  var seller = _ref.seller,
    product = _ref.product,
    scoreDetailsTitle = _ref.scoreDetailsTitle,
    scoreDetailsSidebar = _ref.scoreDetailsSidebar,
    showDialog = _ref.showDialog,
    spa = _ref.spa,
    href = _ref.href;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var details = seller.details;
  var path = "".concat(href).concat(seller == null ? void 0 : seller.id, "/");
  var magaluCompany = magaluCompanies[seller == null ? void 0 : seller.id];
  var isMagaluCompany = !!magaluCompany;
  var showInternationalSeller = isInternationalPrice(product);
  var docSeller = function docSeller() {
    return !showInternationalSeller ? {
      label: 'CNPJ',
      value: cnpjMask(details == null ? void 0 : details.documentNumber)
    } : {
      label: 'ID do Lojista',
      value: details == null ? void 0 : details.shippingDocumentNumber
    };
  };
  var detailsSections = [docSeller(), (details == null ? void 0 : details.legalName) && {
    label: 'Razão Social',
    value: details.legalName
  }, (details == null ? void 0 : details.address) && {
    label: 'Endereço',
    value: getFormattedSellerAddress(details).map(function (text) {
      return React.createElement(DataInfo, {
        key: text
      }, text);
    })
  }];
  var onOffersClick = function onOffersClick(e) {
    publish('sellerdetails:offers:link:click', {
      seller: seller
    });
    if (spa) {
      e.preventDefault();
      routePush({
        path: path,
        spa: spa
      });
    }
  };
  return React.createElement(Box, {
    p: !showDialog ? 3 : undefined,
    "data-testid": "seller-modal-content"
  }, !showDialog && React.createElement(Text, {
    fontWeight: "bold",
    color: "attributeLabel",
    fontSize: 4,
    mb: 4
  }, "Informa\xE7\xF5es da loja parceira"), React.createElement(SellerScore, {
    "static": {
      isMagaluCompany: isMagaluCompany,
      seller: seller
    }
  }), ((_seller$details = seller.details) == null ? void 0 : _seller$details.score) && !isMagaluCompany && React.createElement(ScoreDetails, {
    "static": {
      scoreDetailsSidebar: scoreDetailsSidebar,
      scoreDetailsTitle: scoreDetailsTitle
    },
    isMobile: !showDialog,
    seller: seller
  }), React.createElement(Grid, {
    gridAutoFlow: "column",
    gap: 2,
    py: 24
  }, React.createElement(Icon, {
    name: "Guarantee",
    width: 33,
    height: 30
  }), React.createElement(Box, {
    width: showDialog ? 300 : undefined
  }, !showInternationalSeller && React.createElement(SellerGuaranteeText, {
    isMagaluCompany: isMagaluCompany
  }), showInternationalSeller && React.createElement(Text, {
    fontSize: 1,
    fontWeight: "regular",
    color: "attributeLabel",
    lineHeight: "16px",
    "data-testid": "GuaranteeText"
  }, "O Magalu garante a sua compra, do pedido \xE0 entrega."))), React.createElement(Offers, {
    href: path,
    onClick: onOffersClick,
    "data-testid": "offers-link"
  }, "Ver mais produtos da loja", React.createElement(Icon, {
    color: "background.magablu",
    name: "ChevronRight",
    ml: 2
  })), React.createElement(Hr, null), React.createElement(Flex, {
    gap: 3,
    flexDirection: "column"
  }, details && detailsSections.map(function () {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      label = _ref2.label,
      value = _ref2.value;
    return React.createElement(Box, {
      key: label
    }, React.createElement(DataTitle, null, label), React.createElement(DataInfo, null, value));
  })));
}
ModalContent.defaultProps = {
  href: '',
  product: {},
  scoreDetailsSidebar: '',
  scoreDetailsTitle: '',
  seller: {
    description: '',
    details: {
      address: {}
    },
    id: ''
  },
  showDialog: false,
  spa: false
};
process.env.NODE_ENV !== "production" ? ModalContent.propTypes = {
  href: string,
  product: shape({
    price: shape({
      currency: string
    })
  }),
  scoreDetailsSidebar: string,
  scoreDetailsTitle: string,
  seller: shape({
    description: string,
    details: shape({
      address: shape({})
    }),
    id: string
  }),
  showDialog: bool,
  spa: bool
} : void 0;
ModalContent.ssr = true;
export default ModalContent;