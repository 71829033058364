import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import Component from '@magalu/stereo-ui/molecules/SocialShare';
import { Icon } from '@magalu/stereo-ui-icons';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { parseData } from './SocialShare.utils';
var SocialShare = function SocialShare(_ref) {
  var _structure$config;
  var data = _ref.data,
    structure = _ref.structure,
    _ref$static = _ref["static"],
    socialItemsProps = _ref$static.items,
    _ref$static$hiddenIte = _ref$static.hiddenItems,
    hiddenItems = _ref$static$hiddenIte === void 0 ? [] : _ref$static$hiddenIte,
    linksParameter = _ref$static.linksParameter,
    staticDomain = _ref$static.domain;
  var _data$product = data == null ? void 0 : data.product,
    title = _data$product.title,
    description = _data$product.description,
    url = _data$product.path,
    variationId = _data$product.variationId;
  var domain = staticDomain || (structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.domain);
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var handleShareClick = function handleShareClick(e, item) {
    publish('product:share', {
      iconName: item,
      variationId: variationId
    });
  };
  var links = parseData({
    description: description,
    domain: domain,
    linksParameter: linksParameter,
    title: title,
    url: url
  }, structure);
  var socialItems = socialItemsProps.filter(function (item) {
    return !hiddenItems.includes(item.title);
  }).map(function (_ref2) {
    var icon = _ref2.icon,
      _ref2$color = _ref2.color,
      color = _ref2$color === void 0 ? '#fff' : _ref2$color,
      itemTitle = _ref2.title,
      urlPath = _ref2.url;
    return {
      Icon: React.createElement(Icon, {
        name: icon,
        color: color
      }),
      title: itemTitle,
      url: links == null ? void 0 : links[urlPath]
    };
  });
  return React.createElement(Component, {
    socialItems: socialItems,
    onItemClick: function onItemClick(e, item) {
      return handleShareClick(e, item);
    }
  });
};
SocialShare.defaultProps = {
  data: {
    product: {}
  },
  "static": {
    hiddenItems: [],
    items: [],
    linksParameter: ''
  },
  structure: {
    config: {},
    route: {}
  }
};
process.env.NODE_ENV !== "production" ? SocialShare.propTypes = {
  data: shape({
    product: shape({
      description: string,
      title: string,
      url: string
    })
  }),
  "static": shape({
    domains: string,
    hiddenItems: arrayOf(string),
    items: arrayOf(shape({
      color: string,
      icon: string,
      title: string,
      url: string
    })),
    linksParameter: string
  }),
  structure: shape({
    config: shape({
      domain: string
    }),
    route: shape({
      storeId: string
    })
  })
} : void 0;
SocialShare.ssr = false;
export default withLayoutProps(SocialShare);