import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from 'styled-components';
import { themeGet, getTextColor } from '@magalu/stereo-ui-styles';
import { Text, HorizontalRule } from '@magalu/stereo-ui/atoms';
import { getPaletteColor } from '@magalu/stereo-ui-styles/build/utils';
export var DataInfo = styled(Text).attrs({
  color: 'attributeLabel',
  fontSize: 1
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  line-height: 16px;\n"])));
export var DataTitle = styled(Text).attrs({
  color: 'attributeLabel',
  fontSize: [2, 1],
  fontWeight: 'bold',
  lineHeight: 1.2
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
export var Hr = styled(HorizontalRule)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin: 24px 0;\n  background-color: ", ";\n"])), function (props) {
  return getPaletteColor('background.darker')(props);
});
export var Offers = styled.a(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  margin: 0 auto 0 auto;\n  width: fit-content;\n  padding: ", "px;\n  color: ", ";\n  font-size: ", "px;\n  border-radius: ", ";\n  font-weight: ", ";\n  background-color: ", ";\n  padding-left: 13px;\n  cursor: pointer;\n"])), function (props) {
  return themeGet('space.1')(props);
}, function (props) {
  return getTextColor('primary')(props);
}, function (props) {
  return themeGet('fontSizes.2')(props);
}, function (props) {
  return themeGet('radii.default')(props);
}, function (props) {
  return themeGet('fontWeights.bold')(props);
}, function (props) {
  return getPaletteColor('background.darker')(props);
});