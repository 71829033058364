import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import { Text, Box } from '@magalu/stereo-ui/atoms';
import { getBorderColor } from '@magalu/stereo-ui-styles';
import { getMediaQuery } from '@magalu/stereo-ui-styles/build/utils';
export var Container = styled(Box).attrs(function (props) {
  return _extends({
    pt: 3
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-bottom: 3px solid ", ";\n  border-top: 3px solid ", ";\n\n  ", " {\n    border-top: none;\n    border-bottom: 1px solid ", ";\n  }\n"])), function (props) {
  return getBorderColor('light')(props);
}, function (props) {
  return getBorderColor('light')(props);
}, getMediaQuery('small'), function (props) {
  return getBorderColor('light')(props);
});
export var SellerDetailsStore = styled(Text).attrs(function () {
  return {
    color: 'primary',
    fontWeight: 'medium',
    my: [24, 18],
    px: 2,
    py: 1
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 32px;\n  cursor: pointer;\n\n  ", " {\n    justify-content: flex-start;\n  }\n"])), getMediaQuery('small'));