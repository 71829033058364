import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { shape, arrayOf, func, string } from 'prop-types';
import List from '@magalu/stereo-ui/molecules/List';
import Review from '@magalu/stereo-ui/molecules/Review';
import withLayoutProps from '../../../../hocs/withLayoutProps';
import { usePublisher } from '@magalu/mixer-publisher';
var FiltersUnique = function FiltersUnique(_ref) {
  var searchTerm = _ref.searchTerm,
    onClick = _ref.onClick,
    values = _ref.values,
    type = _ref.type;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var onHandleClick = function onHandleClick(e, filter) {
    e.preventDefault();
    publish('productlist:filter:click', _extends({
      searchTerm: searchTerm
    }, filter));
    onClick(e, filter);
  };
  var reviewLabelFormatter = function reviewLabelFormatter(option) {
    return (option == null ? void 0 : option.hint) ? "".concat(option == null ? void 0 : option.label, " ").concat(option.hint) : "".concat(option == null ? void 0 : option.label);
  };
  var Child = function Child(option) {
    switch (type) {
      case 'review':
        return React.createElement(Review, {
          min: option == null ? void 0 : option.min,
          max: option == null ? void 0 : option.max,
          label: reviewLabelFormatter(option),
          format: "label",
          score: option == null ? void 0 : option.value
        });
      case 'attribute':
        return option == null ? void 0 : option.label;
      default:
        return option == null ? void 0 : option.label;
    }
  };
  return (values == null ? void 0 : values.length) ? React.createElement(React.Fragment, null, React.createElement(List, {
    scale: "small",
    searchProperty: "label",
    disableGutters: true,
    dataSource: values,
    renderItem: function renderItem(option) {
      return React.createElement(List.Radio, {
        as: "span",
        fontSize: 2,
        value: option == null ? void 0 : option.value,
        selected: option == null ? void 0 : option.selected,
        filterLabel: option == null ? void 0 : option.filterLabel,
        eventLabel: option == null ? void 0 : option.label,
        href: option == null ? void 0 : option.href,
        onChange: onHandleClick,
        position: option == null ? void 0 : option.position
      }, Child(option));
    },
    name: "radio-list-".concat(type),
    "data-testid": "unique-filters"
  })) : null;
};
process.env.NODE_ENV !== "production" ? FiltersUnique.propTypes = {
  onClick: func.isRequired,
  searchTerm: string,
  type: string,
  values: arrayOf(shape({}))
} : void 0;
FiltersUnique.defaultProps = {
  searchTerm: '',
  type: '',
  values: []
};
FiltersUnique.ssr = true;
export default withLayoutProps(FiltersUnique);