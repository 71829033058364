import { useEffect } from 'react';
var useScrollLock = function useScrollLock(device, active) {
  useEffect(function () {
    if (device === 'desktop') document.body.style.overflowX = 'hidden';
    if (device === 'mobile' && active) document.body.style.overflow = 'hidden';
    return function () {
      document.body.style.overflowX = 'visible';
      document.body.style.overflow = 'visible';
    };
  }, [active]);
};
export default useScrollLock;