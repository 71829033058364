import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useEffect, useState } from 'react';
import { shape } from 'prop-types';
import { usePublisher } from '@magalu/mixer-publisher';
import AlertText from '../../../AlertText';
var DeliveryAlert = function DeliveryAlert(_ref) {
  var structure = _ref.structure;
  var _useState = useState(''),
    _useState2 = _slicedToArray(_useState, 2),
    message = _useState2[0],
    setMessage = _useState2[1];
  var _usePublisher = usePublisher(),
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe;
  var buildMessage = function buildMessage(modality) {
    var prefixes = {
      conventional: 'Receba',
      store_pickup: 'Retire'
    };
    var prefix = prefixes[modality.type] || '';
    if (!prefix) {
      return false;
    }
    setMessage("".concat(prefix, " este produto ainda hoje"));
    return true;
  };
  var getMessageByDeliveries = function getMessageByDeliveries(_ref2) {
    var _deliveries$;
    var deliveries = _ref2.deliveries;
    deliveries == null ? void 0 : (_deliveries$ = deliveries[0]) == null ? void 0 : _deliveries$.modalities.filter(function (modality) {
      var _modality$shippingTim;
      return (modality == null ? void 0 : (_modality$shippingTim = modality.shippingTime) == null ? void 0 : _modality$shippingTim.unit) === 'hours';
    }).some(function (modality) {
      return buildMessage(modality);
    });
  };
  var clearMessage = function clearMessage() {
    setMessage('');
  };
  useEffect(function () {
    subscribe('product:shipping:haschanged', getMessageByDeliveries);
    subscribe('product:shipping:click', getMessageByDeliveries);
    subscribe('product:shipping:error', clearMessage);
    return function () {
      unsubscribe('product:shipping:haschanged', getMessageByDeliveries);
      unsubscribe('product:shipping:click', getMessageByDeliveries);
      unsubscribe('product:shipping:error', clearMessage);
    };
  });
  useEffect(function () {
    return function () {
      clearMessage();
    };
  }, [JSON.stringify(structure)]);
  if (!message) {
    return null;
  }
  return React.createElement(AlertText, {
    "static": {
      fontWeight: 'medium',
      intent: 'success',
      message: [{
        icon: 'DeliveryRTomorrow',
        text: message
      }]
    }
  });
};
DeliveryAlert.defaultProps = {
  structure: {}
};
process.env.NODE_ENV !== "production" ? DeliveryAlert.propTypes = {
  structure: shape({})
} : void 0;
export default DeliveryAlert;