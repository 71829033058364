import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["title", "terms"];
import React from 'react';
import { shape } from 'prop-types';
import StereoTextList from '@magalu/stereo-ui/organisms/TextList';
import { routePush } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
var SeoLinkList = function SeoLinkList(_ref) {
  var staticProps = _ref["static"];
  var title = staticProps.title,
    terms = staticProps.terms,
    props = _objectWithoutProperties(staticProps, _excluded);
  var renderItems = terms.map(function (item) {
    return [{
      href: item.href,
      value: item.value
    }];
  });
  var onLinkClick = function onLinkClick(e, _ref2) {
    var href = _ref2.href;
    e.preventDefault();
    routePush({
      path: href,
      spa: true
    });
  };
  return React.createElement(StereoTextList, _extends({
    handleClickLink: onLinkClick,
    list: renderItems,
    title: title,
    titleAs: "h2",
    typeList: "inline"
  }, props));
};
SeoLinkList.ssr = true;
process.env.NODE_ENV !== "production" ? SeoLinkList.propTypes = {
  "static": shape({})
} : void 0;
SeoLinkList.defaultProps = {
  "static": {}
};
export default withLayoutProps(SeoLinkList);