import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect } from 'react';
import { shape, arrayOf, func, bool, number, string } from 'prop-types';
import LinkButton from '@magalu/stereo-ui/atoms/Link';
import List from '@magalu/stereo-ui/molecules/List';
import FilterInput from '@magalu/stereo-ui/molecules/FilterInput';
import withLayoutProps from '../../../../hocs/withLayoutProps';
import { usePublisher } from '@magalu/mixer-publisher';
var FiltersMultiple = function FiltersMultiple(_ref) {
  var collapsedIn = _ref.collapsedIn,
    searchTerm = _ref.searchTerm,
    displaySearch = _ref.displaySearch,
    onClickCollapse = _ref.onClickCollapse,
    onClick = _ref.onClick,
    values = _ref.values;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var _useState = useState(true),
    _useState2 = _slicedToArray(_useState, 2),
    trigger = _useState2[0],
    setTrigger = _useState2[1];
  var _useState3 = useState(''),
    _useState4 = _slicedToArray(_useState3, 2),
    filterTerm = _useState4[0],
    setFilterTerm = _useState4[1];
  useEffect(function () {
    setTrigger((values == null ? void 0 : values.length) >= collapsedIn);
  }, [JSON.stringify(values)]);
  var onHandleClick = function onHandleClick(e, filter) {
    e.preventDefault();
    publish('productlist:filter:click', _extends({
      searchTerm: searchTerm
    }, filter));
    onClick(e, filter);
  };
  var onHandleInputChange = function onHandleInputChange(e) {
    if (trigger) setTrigger(false);
    setFilterTerm(e.target.value);
  };
  var onHandleCollapseClick = function onHandleCollapseClick() {
    setTrigger(!trigger);
    if (!trigger) onClickCollapse();
  };
  return (values == null ? void 0 : values.length) ? React.createElement(React.Fragment, null, displaySearch && React.createElement(FilterInput, {
    mb: 3,
    onChange: onHandleInputChange,
    onClearInput: function onClearInput() {
      return setFilterTerm('');
    },
    "data-testid": "filter-input"
  }), React.createElement(List, {
    scale: "small",
    collapse: (values == null ? void 0 : values.length) >= collapsedIn,
    trigger: trigger,
    collapsedIn: (values == null ? void 0 : values.length) <= collapsedIn ? values == null ? void 0 : values.length : collapsedIn,
    dataSource: values,
    renderItem: function renderItem(filter) {
      return React.createElement(List.Checkbox, {
        key: filter == null ? void 0 : filter.slug,
        as: "span",
        checked: filter == null ? void 0 : filter.selected,
        fontSize: 2,
        onClick: function onClick(e) {
          return onHandleClick(e, filter);
        },
        "data-testid": "filter-checkbox"
      }, filter == null ? void 0 : filter.label);
    },
    renderAction: (values == null ? void 0 : values.length) >= collapsedIn && React.createElement(List.Action, null, React.createElement(LinkButton, {
      color: "text.primary",
      fontWeight: "medium",
      fontSize: 2,
      px: 0,
      onClick: onHandleCollapseClick,
      "data-testid": "filter-action"
    }, trigger ? 'Ver todos' : 'Ver menos')),
    searchProperty: "label",
    searchTerm: filterTerm,
    sortBy: function sortBy(_ref2) {
      var selected = _ref2.selected;
      return selected ? -1 : 1;
    },
    disableGutters: true
  })) : null;
};
process.env.NODE_ENV !== "production" ? FiltersMultiple.propTypes = {
  collapsedIn: number,
  displaySearch: bool,
  onClick: func,
  onClickCollapse: func,
  searchTerm: string,
  values: arrayOf(shape({}))
} : void 0;
FiltersMultiple.defaultProps = {
  collapsedIn: 5,
  displaySearch: false,
  onClick: function onClick() {},
  onClickCollapse: function onClickCollapse() {},
  searchTerm: '',
  values: []
};
FiltersMultiple.ssr = true;
export default withLayoutProps(FiltersMultiple);