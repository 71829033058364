import React from 'react';
import Head from 'next/head';
import { arrayOf, shape, string } from 'prop-types';
var isDenied = function isDenied(term, deniedTerms) {
  return deniedTerms.indexOf(term) >= 0;
};
var getDeniedTitle = function getDeniedTitle(term, deniedTerms, deniedTitle) {
  return isDenied(term, deniedTerms) ? deniedTitle : null;
};
var getDeniedDescription = function getDeniedDescription(term, deniedTerms, deniedDescription) {
  return isDenied(term, deniedTerms) ? deniedDescription : null;
};
var SearchTitle = function SearchTitle(_ref) {
  var structure = _ref.structure,
    staticProps = _ref["static"];
  var _structure$route = structure == null ? void 0 : structure.route,
    term = _structure$route.term;
  var defaultTitle = staticProps.defaultTitle,
    defaultDescription = staticProps.defaultDescription,
    _staticProps$deniedTe = staticProps.deniedTerms,
    deniedTerms = _staticProps$deniedTe === void 0 ? [] : _staticProps$deniedTe,
    deniedTitle = staticProps.deniedTitle,
    deniedDescription = staticProps.deniedDescription,
    allowedDescription = staticProps.allowedDescription,
    allowedTitle = staticProps.allowedTitle;
  var title = !term ? defaultTitle : getDeniedTitle(term, deniedTerms, deniedTitle) || allowedTitle || defaultTitle;
  var description = !term ? defaultDescription : getDeniedDescription(term, deniedTerms, deniedDescription) || allowedDescription || defaultDescription;
  return React.createElement(Head, null, React.createElement("title", {
    "data-testid": "meta-title"
  }, title), React.createElement("meta", {
    "data-testid": "meta-description",
    name: "description",
    content: description
  }));
};
SearchTitle.displayName = 'SearchTitle';
SearchTitle.defaultProps = {
  "static": {
    allowedDescription: '',
    allowedTitle: '',
    defaultDescription: '',
    defaultTitle: '',
    deniedDescription: '',
    deniedTerms: [],
    deniedTitle: ''
  },
  structure: {
    route: {}
  }
};
process.env.NODE_ENV !== "production" ? SearchTitle.propTypes = {
  "static": shape({
    allowedDescription: string,
    allowedTitle: string,
    defaultDescription: string,
    defaultTitle: string,
    deniedDescription: string,
    deniedTerms: arrayOf(string),
    deniedTitle: string
  }),
  structure: shape({
    route: shape({})
  })
} : void 0;
SearchTitle.ssr = true;
export default SearchTitle;