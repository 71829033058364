import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import styled from 'styled-components';
import { themeGet, getTextColor } from '@magalu/stereo-ui-styles';
import { getPaletteColor } from '@magalu/stereo-ui-styles/build/utils';
export var ShowDetails = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  text-transform: none;\n  width: 100%;\n  justify-content: space-between;\n  margin-top: ", "px;\n  padding: ", "px;\n  color: ", ";\n  font-size: ", "px;\n  border-radius: ", ";\n  font-weight: ", ";\n  background-color: ", ";\n  border: ", ";\n  cursor: pointer;\n"])), function (props) {
  return themeGet('space.3')(props);
}, function (props) {
  return themeGet('space.2')(props);
}, function (props) {
  return getTextColor('attributeLabel')(props);
}, function (props) {
  return themeGet('fontSizes.1')(props);
}, function (props) {
  return themeGet('radii.default')(props);
}, function (props) {
  return themeGet('fontWeights.regular')(props);
}, function (props) {
  return getPaletteColor('background.transparent')(props);
}, function (props) {
  return themeGet('borders.0')(props);
});