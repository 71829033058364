import React from 'react';
import { bool } from 'prop-types';
import { Text } from '@magalu/stereo-ui';
var SellerGuaranteeText = function SellerGuaranteeText(_ref) {
  var isMagaluCompany = _ref.isMagaluCompany;
  var getTextIntro = function getTextIntro() {
    return isMagaluCompany ? 'Este produto é vendido por uma empresa do grupo Magalu' : 'Este produto é vendido por um lojista parceiro';
  };
  var getTextBody = function getTextBody() {
    return isMagaluCompany ? 'Todas as empresas do nosso ecossistema compartilham do mesmo propósito, oferecendo uma experiência diferenciada.' : 'Nossos parceiros são selecionados e avaliados por critérios de excelência no serviço.';
  };
  return React.createElement(Text, {
    fontSize: 1,
    fontWeight: "regular",
    color: "attributeLabel",
    lineHeight: "16px",
    "data-testid": "GuaranteeText"
  }, getTextIntro(), "\xA0e o\xA0", React.createElement(Text, {
    as: "span",
    fontWeight: "bold"
  }, "Magalu garante a entrega.\xA0"), getTextBody(), "\xA0Certificamos as vendas com nota fiscal e garantimos produtos originais. Se tem no Magalu, t\xE1 garantido!");
};
SellerGuaranteeText.defaultProps = {
  isMagaluCompany: false
};
process.env.NODE_ENV !== "production" ? SellerGuaranteeText.propTypes = {
  isMagaluCompany: bool
} : void 0;
export default SellerGuaranteeText;