import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["searchTerm", "onApply"],
  _excluded2 = ["buildHref"];
import React from 'react';
import { func, string, number } from 'prop-types';
import PriceRange from '@magalu/stereo-ui/molecules/PriceRange';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../../hocs/withLayoutProps';
var FilterPrice = function FilterPrice(_ref) {
  var searchTerm = _ref.searchTerm,
    onApply = _ref.onApply,
    filterProps = _objectWithoutProperties(_ref, _excluded);
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var max = filterProps.max,
    min = filterProps.min,
    selectedMin = filterProps.selectedMin,
    selectedMax = filterProps.selectedMax;
  var handleApply = function handleApply(e, appliedRange, filter) {
    e.preventDefault();
    var buildHref = filter.buildHref,
      appliedFilter = _objectWithoutProperties(filter, _excluded2);
    publish('productlist:filter:click', _extends({
      searchTerm: searchTerm
    }, appliedFilter, {
      selectedMax: appliedRange.max,
      selectedMin: appliedRange.min
    }));
    onApply(e, appliedRange, filter);
  };
  return React.createElement(PriceRange, {
    item: filterProps,
    max: max,
    min: min,
    initialMax: selectedMax,
    initialMin: selectedMin,
    onApply: handleApply
  });
};
process.env.NODE_ENV !== "production" ? FilterPrice.propTypes = {
  max: number.isRequired,
  min: number.isRequired,
  onApply: func.isRequired,
  searchTerm: string,
  selectedMax: number,
  selectedMin: number
} : void 0;
FilterPrice.defaultProps = {
  searchTerm: '',
  selectedMax: undefined,
  selectedMin: undefined
};
FilterPrice.ssr = true;
export default withLayoutProps(FilterPrice);