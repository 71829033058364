import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
import styled, { css } from 'styled-components';
import { themeGet } from '@magalu/stereo-ui-styles';
export var Grid = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: auto auto;\n\n  & label {\n    padding-top: 0;\n  }\n"])));
export var LoadingContent = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  width: 100%;\n"])));
export var WidgetContentContainer = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  height: 285px;\n  padding: 16px;\n  transition: all 0.3s ease-out;\n\n  ", "\n\n  ", "\n\n  ", "\n"])), function (props) {
  return themeGet('palette.background.white')(props);
}, function (_ref) {
  var device = _ref.device;
  return device === 'mobile' && css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      border-top-left-radius: 32px;\n      border-top-right-radius: 32px;\n      bottom: -285px;\n      left: 0;\n      position: fixed;\n      right: 0;\n      width: 100%;\n    "])));
}, function (_ref2) {
  var device = _ref2.device;
  return device === 'desktop' && css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n      width: 390px;\n      height: 226px;\n      padding: 24px;\n    "])));
}, function (_ref3) {
  var active = _ref3.active;
  return active && css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n      z-index: 2;\n      bottom: 0;\n      transition: all 0.3s ease-in;\n    "])));
});