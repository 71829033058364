var getSalesLabel = function getSalesLabel(value) {
  var _findLabels$label;
  var labels = [
  {
    label: '+1 milhão',
    limit: 1000000
  }, {
    label: '+500mil',
    limit: 500000
  }, {
    label: '+200mil',
    limit: 200000
  }, {
    label: '+100mil',
    limit: 100000
  }, {
    label: '+50mil',
    limit: 50000
  }, {
    label: '+20mil',
    limit: 20000
  }, {
    label: '+10mil',
    limit: 10000
  }, {
    label: '+5mil',
    limit: 5000
  }, {
    label: '+1mil',
    limit: 1000
  }, {
    label: '+500',
    limit: 500
  }, {
    label: '+200',
    limit: 200
  }, {
    label: '+100',
    limit: 100
  }, {
    label: '+50',
    limit: 50
  }, {
    label: '+10',
    limit: 10
  }];
  var findLabels = labels.find(function (faixa) {
    return value >= faixa.limit;
  });
  return (_findLabels$label = findLabels == null ? void 0 : findLabels.label) !== null && _findLabels$label !== void 0 ? _findLabels$label : '';
};
var getSellerRole = function getSellerRole(isMagaluCompany, seller) {
  var _seller$details, _seller$details2, _seller$details3;
  var isNewSeller = (seller == null ? void 0 : (_seller$details = seller.details) == null ? void 0 : _seller$details.score) === null;
  var sellerSince = seller == null ? void 0 : (_seller$details2 = seller.details) == null ? void 0 : _seller$details2.sellerSince;
  var isDocShipping = seller == null ? void 0 : (_seller$details3 = seller.details) == null ? void 0 : _seller$details3.shippingDocumentNumber;
  var sellerYear = sellerSince == null ? void 0 : sellerSince.substr(0, 4);
  if (!isMagaluCompany) {
    if (isNewSeller || !sellerYear) {
      return 'Lojista Magalu';
    }
    return "Lojista Magalu desde ".concat(sellerYear);
  }
  if (isMagaluCompany && isDocShipping) {
    return 'Loja parceiro internacional';
  }
  return 'Loja do grupo Magalu';
};
var handleScoreColor = function handleScoreColor(score) {
  var defaultColor = '#B8C1C4';
  var scoreRanges = [{
    color: defaultColor,
    max: 0
  }, {
    color: '#E3172C',
    max: 1.49
  }, {
    color: '#FD8A25',
    max: 1.99
  }, {
    color: '#EEB510',
    max: 2.49
  }, {
    color: '#F9C52E',
    max: 2.99
  }, {
    color: '#5EBE09',
    max: 3.99
  }, {
    color: '#4EA900',
    max: 5
  }];
  var range = scoreRanges.find(function (_ref) {
    var max = _ref.max;
    return score <= max;
  });
  return range ? range.color : defaultColor;
};
export { getSalesLabel, getSellerRole, handleScoreColor };