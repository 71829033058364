import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
import styled from 'styled-components';
import { Link, Button } from '@magalu/stereo-ui/atoms';
import { themeGet, getPaletteColor } from '@magalu/stereo-ui-styles';
export var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding-bottom: 48px;\n"])));
export var Content = styled.nav(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding: 0 8px;\n"])));
export var IconWrapper = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: ", ";\n  background-color: ", ";\n  box-shadow: ", " 0 0 0 1px inset;\n  width: 25px;\n  height: 25px;\n"])), function (props) {
  return themeGet('radii.circle')(props);
}, function (props) {
  return getPaletteColor('base')(props);
}, function (props) {
  return getPaletteColor('background.whisper')(props);
});
export var LoginButton = styled(Button)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  color: ", ";\n\n  label {\n    font-weight: ", ";\n  }\n\n  &:hover {\n    label {\n      font-weight: ", ";\n    }\n  }\n"])), function (props) {
  return getPaletteColor('pmd.base')(props);
}, function (props) {
  return themeGet('fontWeights.regular')(props);
}, function (props) {
  return themeGet('fontWeights.medium')(props);
});
export var LogoutLink = styled(Link).attrs(function () {
  return {
    color: 'primary.base',
    marginBottom: -5,
    marginLeft: -8,
    marginRight: -8,
    paddingBottom: 5,
    paddingTop: 3
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral([""])));
export var RegisterLink = styled(LogoutLink)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  background: transparent;\n  color: ", ";\n  margin-bottom: 16px;\n  padding: 0;\n"])), themeGet('colors.white'));