import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import styled from 'styled-components';
import { Box, Text } from '@magalu/stereo-ui';
import { themeGet } from '@magalu/stereo-ui-styles';
export var IconContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  height: 64px;\n"])));
export var NewSellerBadge = styled(Text).attrs({
  bg: 'background.primary',
  borderRadius: 'small',
  color: 'white',
  fontSize: 1,
  p: 1
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: absolute;\n  bottom: -10px;\n  left: 50%;\n  transform: translateX(-50%);\n  border-radius: ", ";\n"])), function (props) {
  return themeGet('radii.default')(props);
});