import React from 'react';
import { string, bool, func } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Box from '@magalu/stereo-ui/atoms/Box';
import Radio from '@magalu/stereo-ui/atoms/Radio';
import Text from '@magalu/stereo-ui/atoms/Text';
import CopyToClipboard from '@magalu/stereo-ui/molecules/CopyToClipboard';
import { Icon } from '@magalu/stereo-ui-icons';
import { Grid, LoadingContent, WidgetContentContainer } from './WidgetContent.styles';
var WidgetContent = function WidgetContent(_ref) {
  var active = _ref.active,
    device = _ref.device,
    hasError = _ref.hasError,
    loading = _ref.loading,
    urlToCopy = _ref.urlToCopy,
    urlType = _ref.urlType,
    onChangeRadio = _ref.onChangeRadio;
  return React.createElement(WidgetContentContainer, {
    active: active,
    device: device
  }, React.createElement(Flex, {
    flexDirection: "column",
    width: ['100%']
  }, hasError && React.createElement(Box, null, React.createElement(Text, {
    as: "h2",
    fontSize: 2,
    lineHeight: 1.5,
    fontWeight: "bold",
    mb: 4,
    mt: 2,
    textAlign: "center"
  }, "Indisponivel temporariamente..."), React.createElement(Text, {
    fontSize: 1,
    lineHeight: 1.25,
    fontWeight: "normal",
    mb: 2,
    mt: 2
  }, "Estamos trabalhando para normalizar o mais breve poss\xEDvel. Por favor, tente novamente em instantes.")), loading ? React.createElement(LoadingContent, {
    "data-testid": "loading-content"
  }, React.createElement(Icon, {
    name: "AnimatedLoading3",
    color: "primary.base",
    height: 40,
    width: 40
  })) : !hasError && React.createElement(Box, null, React.createElement(Text, {
    as: "h2",
    fontSize: 2,
    lineHeight: 1.5,
    fontWeight: "bold",
    mb: 4,
    mt: 2,
    textAlign: device === 'mobile' ? 'center' : 'left'
  }, "Encurtador de link"), React.createElement(Text, {
    fontSize: 1,
    lineHeight: 1.25,
    fontWeight: "normal",
    mb: 2,
    mt: 2
  }, "Copie o link e compartilhe os produtos desta p\xE1gina"), React.createElement(Box, {
    mb: 3
  }, React.createElement(CopyToClipboard, {
    value: urlToCopy,
    style: {
      backgroundColor: '#ececec',
      cursor: 'default'
    }
  })), React.createElement(Grid, null, React.createElement(Radio, {
    label: "Link curto",
    value: "shorten",
    checked: urlType === 'shorten',
    onChange: onChangeRadio
  }), React.createElement(Radio, {
    label: "Link completo",
    value: "full",
    checked: urlType === 'full',
    onChange: onChangeRadio
  })))));
};
process.env.NODE_ENV !== "production" ? WidgetContent.propTypes = {
  active: bool,
  device: string,
  hasError: bool,
  loading: bool,
  onChangeRadio: func,
  urlToCopy: string,
  urlType: string
} : void 0;
WidgetContent.defaultProps = {
  active: false,
  device: 'mobile',
  hasError: false,
  loading: false,
  onChangeRadio: null,
  urlToCopy: '',
  urlType: 'full'
};
export default WidgetContent;