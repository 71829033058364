import _extends from "@babel/runtime/helpers/esm/extends";
import React, { useCallback, useEffect } from 'react';
import { bool, shape, string, number } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import ProductList from '@magalu/stereo-ui/organisms/ProductList';
import ProductCarousel from '@magalu/stereo-ui/organisms/ProductCarousel';
import { parsePath, routePush } from '@magalu/mixer-utils';
import { useRecommendation } from '@magalu/mixer-graphql';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
var SearchRecommendation = function SearchRecommendation(_ref) {
  var _recommendation$produ, _structure$cookies, _structure$cookies$ac, _structure$route;
  var structure = _ref.structure,
    staticProps = _ref["static"];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var _ref2 = structure.route || {},
    term = _ref2.term,
    filters = _ref2.filters,
    sortOrientation = _ref2.sortOrientation,
    sortType = _ref2.sortType,
    _ref2$page = _ref2.page,
    page = _ref2$page === void 0 ? 1 : _ref2$page;
  var _ref3 = (structure == null ? void 0 : structure.config) || {},
    domain = _ref3.domain;
  var _ref4 = (structure == null ? void 0 : structure.cookies) || {},
    _ref4$location = _ref4.location,
    location = _ref4$location === void 0 ? {} : _ref4$location,
    partnerId = _ref4.partnerId,
    _ref4$customerId = _ref4.customerId,
    customerId = _ref4$customerId === void 0 ? '0' : _ref4$customerId;
  var _staticProps$display = staticProps.display,
    display = _staticProps$display === void 0 ? 'list' : _staticProps$display,
    _staticProps$origin = staticProps.origin,
    origin = _staticProps$origin === void 0 ? 'doory' : _staticProps$origin,
    _staticProps$pageSize = staticProps.pageSize,
    pageSize = _staticProps$pageSize === void 0 ? 20 : _staticProps$pageSize,
    _staticProps$spa = staticProps.spa,
    spa = _staticProps$spa === void 0 ? false : _staticProps$spa,
    _staticProps$type = staticProps.type,
    type = _staticProps$type === void 0 ? 'list' : _staticProps$type,
    _staticProps$carousel = staticProps.carouselConfig,
    carouselConfig = _staticProps$carousel === void 0 ? {} : _staticProps$carousel;
  var _useRecommendation = useRecommendation({
      customerId: customerId,
      filters: filters,
      location: location,
      partnerId: partnerId,
      sortOrientation: sortOrientation,
      sortType: sortType,
      term: term
    }),
    recommendation = _useRecommendation.recommendation;
  var productsParsed = recommendation == null ? void 0 : (_recommendation$produ = recommendation.products) == null ? void 0 : _recommendation$produ.map(function (product, index) {
    var path = product.path;
    var url = parsePath(path);
    var position = (page - 1) * pageSize + (index + 1);
    return _extends({}, product, {
      position: position,
      url: url
    });
  });
  var eventData = {
    customer_id: structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.customer_id,
    display: display,
    filters: filters,
    list: recommendation == null ? void 0 : recommendation.title,
    listPosition: 2,
    origin: origin,
    pageName: structure == null ? void 0 : structure.name,
    term: structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.term,
    type: 'recomendacao'
  };
  useEffect(function () {
    if (productsParsed) {
      publish('productlist:impression', _extends({}, eventData, {
        products: productsParsed
      }));
    }
  }, [recommendation]);
  var handleProductView = function handleProductView(product) {
    publish('product:view', _extends({}, eventData, {
      product: _extends({}, product, {
        position: product.index + 1
      })
    }));
    publish('product:viewItemList', _extends({}, eventData, product));
  };
  var handleProductClick = useCallback(function (e, product) {
    publish('productlist:selectItem:click', _extends({}, eventData, product));
    publish('productlist:click', _extends({}, product, eventData));
    if (spa) {
      e.preventDefault();
      routePush({
        path: product.url,
        spa: spa
      });
    }
  }, [recommendation.title]);
  var showcases = {
    carousel: React.createElement(ProductCarousel, _extends({
      products: productsParsed,
      displayMode: display,
      onProductView: handleProductView,
      showArrowControls: true,
      showPartialNextSlide: false,
      slidesPerView: 4,
      showControls: false,
      auto: false
    }, carouselConfig)),
    list: React.createElement(ProductList, {
      onClick: handleProductClick,
      onProductView: handleProductView,
      data: productsParsed,
      displayMode: display,
      domain: domain
    })
  };
  var showcase = showcases[type];
  return React.createElement(React.Fragment, null, productsParsed && React.createElement(Box, null, React.createElement(Heading, {
    "data-testid": "recommendation-title",
    as: "h3",
    fontSize: [2, 5, 5, 5],
    fontWeight: "medium",
    m: "0 8px 12px"
  }, recommendation == null ? void 0 : recommendation.title), showcase));
};
process.env.NODE_ENV !== "production" ? SearchRecommendation.propTypes = {
  "static": shape({
    display: string,
    origin: string,
    pageSize: number,
    spa: bool
  }),
  structure: shape({
    config: shape({}),
    cookies: shape({}),
    route: shape({})
  })
} : void 0;
SearchRecommendation.defaultProps = {
  "static": {},
  structure: {
    config: {},
    cookies: {},
    route: {}
  }
};
SearchRecommendation.displayName = 'SearchRecommendation';
SearchRecommendation.ssr = false;
export default withLayoutProps(SearchRecommendation);